@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.outer-wrapper {
  display: flex;
  justify-content: center;
  margin-top: var(--BoxCta__margin-top, 1.5rem);

  @include media('>=tablet-landscape') {
    margin-top: var(--BoxCta__margin-top, 3rem);
  }
}

.main {
  @include fonts__h6;

  display: inline-block;
  border: 1px solid var(--BoxCta__border-color, var(--colors__black));
  padding: 1rem 1.5rem;
  background: linear-gradient(var(--colors__gold), var(--colors__gold));
  background-size: var(--BoxCta__background-size, 0% 100%);
  background-repeat: no-repeat;
  background-position: 50% 50%;

  @include transition(background-size, border-color);

  &:hover,
  &:focus-visible {
    border-color: var(--colors__gold);
    background-size: var(--BoxCta__background-size, 100% 100%);
  }
}
