@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  background: var(--colors__white);
  z-index: var(--navigation-z-index);
  position: sticky;
  top: 0;
  width: 100%;

  @include transition(transform);

  &--hidden {
    transform: translateY(-100%);
  }

  &--homepage {
    position: fixed;
  }

  @include target-print {
    transform: none;
    position: relative;
  }
}

.skip-link {
  position: absolute;
  top: 0.5rem;
  left: 1.5rem;
  z-index: 1000;
  line-height: 1;
  opacity: 0;

  @include transition(opacity);

  &:focus-visible {
    opacity: 1;
  }
}

.header-container {
  position: relative;
  background: var(--colors__white);
  z-index: 2;
}

.top {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  padding: 32px;
  position: relative;
  z-index: 4;
  background: var(--colors__white);
}

.bottom {
  padding: 2rem;
  padding-bottom: 0;
  z-index: 1;
  position: relative;
  background: var(--colors__white);
  display: none;

  &::before {
    content: '';
    background: var(--colors__white);
    width: 100%;
    position: absolute;
    z-index: 1;
    height: 100%;
    left: 0;
    top: 0;
  }

  @include media('>=tablet-landscape') {
    display: block;
  }
}

.top-container {
  position: relative;
  z-index: 2;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    background: var(--colors__white);
    z-index: 2;
  }
}

.links {
  display: flex;
  justify-content: space-between;
}

.menu-toggle {
  display: flex;
  justify-content: flex-start;

  @include target-print {
    opacity: 0;
  }
}

.logo {
  display: flex;
  justify-content: center;
}

.logo-image {
  width: 166px;
  position: relative;

  --Image__object-fit: contain;
}

.search-toggle {
  display: flex;
  justify-content: flex-end;

  @include target-print {
    opacity: 0;
  }
}

.magnifying {
  path {
    @include transition(fill);
  }
}

.header-links {
  padding: 1rem;
  display: block;
  position: relative;
  z-index: 2;

  &::after {
    bottom: 0;
  }
}

.languages {
  display: block;

  @include media('>=tablet-landscape') {
    display: none;
  }

  @include target-print {
    display: none;
  }
}
