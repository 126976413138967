@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

$facet-label-gap: 16px;
$facet-checkbox-dimensions: 16px;

.facet-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 24px;

  @include media('>=tablet-landscape') {
    flex-direction: row;
  }
}

.facet-list-item {
  flex-grow: 1;
  flex-shrink: 0;
}

.facet-select-label {
  @include transition(border-top);

  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 20px 0;
  border-top: 1px solid var(--colors__gray-1);

  &:hover,
  &:focus-visible,
  &:focus-within {
    border-top: 1px solid var(--colors__gold);
  }

  &:focus-within {
    @include focus;
  }

  &.disabled-facet {
    border-top: 1px solid var(--colors__gray-1);
  }
}

.facet-select {
  @include fonts__h6;

  appearance: none;
  opacity: 0;
  border: none;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
  }
}

.facet-select-name {
  @include fonts__h6;

  text-transform: uppercase;
  max-width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .disabled-facet & {
    color: var(--colors__gray-2);
  }
}

.facet-icon {
  @include transition(transform);

  position: absolute;
  right: 0;
  top: 0;
  top: 50%;
  transform: translateY(-50%);
}
