@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.toggle {
  padding: 1rem;
  text-align: center;

  @include fonts__h6;

  width: 100%;
  color: var(--colors__gray-2);
  position: relative;

  &:focus-visible {
    outline-color: var(--colors__black);
    outline-offset: -0.5rem;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100vw;
    background: var(--colors__gray-1);
    height: 1px;
    left: 0;
    top: 100%;
  }

  &::after {
    top: 0;
  }

  @include media('>=tablet-landscape') {
    display: none;

    &::before,
    &::after {
      content: none;
    }
  }
}

.divider {
  border-top: 1px solid var(--colors__gray-1);

  @include media('>=tablet-landscape') {
    border-top: none;
  }
}

.list-wrapper {
  display: grid;
  grid-template-rows: 0fr;

  @include transition(grid-template-rows, visibility);

  visibility: hidden;

  &.active {
    grid-template-rows: 1fr;
    visibility: visible;
    height: 100%;
    max-height: calc(100vh - (var(--NavigationTop__height) + var(--Languages__button-height)));
  }

  @include media('>=tablet-landscape') {
    grid-template-rows: 1fr;
    visibility: visible;
  }
}

.list {
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .active > & {
    padding-bottom: 8px;
  }

  @include media('>=tablet-landscape') {
    flex-direction: row;
  }
}

.lang {
  display: flex;
  align-items: center;

  @include media('>=tablet-landscape') {
    &:not(:last-child) {
      &::after {
        content: '|';
      }
    }
  }
}

.lang-link {
  @include fonts__h6;

  padding: 0.5rem;
  display: inline-block;
}

a.lang-link {
  @include underline;

  &:focus {
    @include focus-inset;
  }
}

.arrow {
  transform: rotate(90deg);
  margin-left: 0.5rem;

  @include transition(transform);

  &--active {
    transform: rotate(90deg) rotateY(-180deg);
  }
}
