@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

$relatedContentBorder: 1px solid var(--colors__gray-1);

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grid-container {
  width: 100%;

  @include media('>=tablet-portrait') {
    display: grid;
    grid-template-columns: repeat(auto-fill, 50%);
    grid-auto-rows: auto;
  }

  &.hide-images {
    @include media('>=tablet-portrait') {
      display: flex;
      justify-content: center;
    }
  }
}

.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.grid-item__main {
  border-bottom: $relatedContentBorder;
  padding: 0 16px 24px;
  width: 100%;

  @include media('>=tablet-portrait') {
    align-self: stretch;
    grid-column: 1 / 2;
    grid-row: 1 / span calc(var(--card-count, 4) - 1);
    border-bottom: none;
    padding-bottom: 0;
    border-right: $relatedContentBorder;
  }
}

.grid-item__side {
  padding: 24px 16px;
  width: 100%;
  grid-area: span 1 / span 1;
  justify-self: center;

  &:not(:last-of-type) {
    border-bottom: $relatedContentBorder;
  }

  @include media('>=tablet-portrait') {
    max-width: 379px;
    margin-left: 16px;
  }
}

.grid-item__hide-images {
  align-items: flex-start;
  align-self: stretch;
  margin: 0;
  padding: 48px 16px;
  height: auto;
  width: 100%;

  &:not(:last-of-type) {
    border-bottom: $relatedContentBorder;

    @include media('>=tablet-portrait') {
      border-bottom: none;
      border-right: $relatedContentBorder;
    }
  }

  @include media('>=tablet-landscape') {
    padding: 48px 64px;
    min-height: 268px;
  }
}
