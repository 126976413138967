@mixin container() {
  margin: 0 auto;
  max-width: var(--container__max-width, 1158px);
  padding-left: var(--container__padding-x, 16px);
  padding-right: var(--container__padding-x, 16px);
  width: 100%;
  box-sizing: border-box;
}

@mixin overlay-background() {
  background-color: rgb(var(--colors__black-rgb) / 60%);
  backdrop-filter: blur(2.5px);
}

@mixin focus() {
  outline: 2px dotted;
  outline-offset: 2px;
}

@mixin focus-inset() {
  @include focus;

  outline-offset: -0.25rem;
}
