@import "src/styles/sass-resources/sass-resources";
@import './../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex-grow: 1;
}

.grid {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-bottom: 2rem;

  @include media('>=PeopleDetailLayout__two-column') {
    flex-direction: row;
    justify-content: space-between;
  }

  @include media('>=desktop') {
    gap: 3.5rem;
  }

  @include media('>=desktop-large') {
    gap: 4rem;
  }
}

.left-column {
  z-index: 1;

  @include media('>=PeopleDetailLayout__two-column') {
    width: 48%;

    // 644px is based on the recommended image width for the bio photo
    max-width: 644px;
  }
}

.right-column {
  --container__padding-x: 0;

  @include media('>=PeopleDetailLayout__two-column') {
    width: 50%;
    padding-top: 12px;
  }

  @include media('>=desktop') {
    --container__padding-x: 1rem;
  }

  @include media('>=desktop-large') {
    --container__padding-x: 2rem;
  }
}

.share-tray {
  @include media('>=PeopleDetailLayout__two-column') {
    display: none;
  }
}
