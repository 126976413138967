@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  justify-content: center;
}

.container {
  display: inline-flex;
  flex-direction: var(--BasicContentShareTray__flex-direction, column);
  align-items: center;
  gap: 1rem;
}

.share-label {
  color: var(--colors__black);

  @include fonts__link-small;
}

.platforms {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
