@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  z-index: 0;
  position: relative;
}

.tab-navs-container {
  border-bottom: 1px solid var(--colors__gray-1);
}

.tab-navs {
  display: flex;
  position: relative;
  justify-content: center;
  column-gap: 30px;
  padding-bottom: 2px;
  flex-wrap: wrap;

  &:focus-visible {
    @include focus;
  }
}

.panels {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
}
