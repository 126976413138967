/*
 *  Variables
 */

$fonts__base-size: 16px;
$fonts__font-weight--book: 300;
$fonts__font-weight--regular: 400;
$fonts__font-weight--medium: 500;
$fonts__font-weight--heavy: 700;
$fonts__font-family--avenir: avenir;
$fonts__font-family--burgess-pro: burgess pro;
$fonts__font-family--arial: arial;
$fonts__font-family--georgia: georgia;
$fonts__font-family--sans-serif: $fonts__font-family--avenir, Arial, sans-serif;
$fonts__font-family--serif: $fonts__font-family--burgess-pro, Georgia, serif;

/*
 *  Mixins
 */

@mixin font-face($font-name, $file-path-location, $font-weight, $font-style) {
  @font-face {
    font-family: $font-name;
    src: url(#{$file-path-location}) format('woff2');
    font-weight: $font-weight;
    font-style: $font-style;
    font-display: swap;
  }
}

/*
 *  Base font mixins
 */

@mixin fonts__sans-serif-book() {
  font-family: var(--font-family__sans-serif);
  font-weight: var(--font-weight__book);
}

@mixin fonts__sans-serif-book-italic() {
  font-family: var(--font-family__sans-serif);
  font-weight: var(--font-weight__book);
  font-style: italic;
}

@mixin fonts__sans-serif-medium() {
  font-family: var(--font-family__sans-serif);
  font-weight: var(--font-weight__medium);
}

@mixin fonts__sans-serif-medium-italic() {
  font-family: var(--font-family__sans-serif);
  font-weight: var(--font-weight__medium);
  font-style: italic;
}

@mixin fonts__sans-serif-heavy() {
  font-family: var(--font-family__sans-serif);
  font-weight: var(--font-weight__heavy);
}

@mixin fonts__serif() {
  font-family: var(--font-family__serif);
  font-weight: var(--font-weight__regular);
}

@mixin fonts__serif-italic() {
  font-family: var(--font-family__serif);
  font-weight: var(--font-weight__regular);
  font-style: italic;
}

/*
 *  Font Styles according to how they are defined in Figma.
 */

// Desktop Mixins
@mixin fonts__h1 {
  @include fonts__sans-serif-medium;

  font-size: 36px;
  line-height: 1.25;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  @include media('>tablet-landscape') {
    font-size: 64px;
  }
}

@mixin fonts__h2 {
  @include fonts__sans-serif-medium;

  font-size: 32px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  @include media('>tablet-landscape') {
    font-size: 40px;
  }
}

@mixin fonts__h2-title-case {
  @include fonts__h2;

  text-transform: none;
}

@mixin fonts__h3 {
  @include fonts__sans-serif-medium;

  font-size: 24px;

  @include media('>tablet-landscape') {
    font-size: 32px;
  }
}

@mixin fonts__h3-underline {
  @include fonts__h3;

  text-decoration: underline;
}

@mixin fonts__h3-all-caps {
  @include fonts__h3;

  letter-spacing: 0.06em;
  text-transform: uppercase;
}

@mixin fonts__h4 {
  @include fonts__sans-serif-medium;

  font-size: 20px;

  @include media('>tablet-landscape') {
    font-size: 24px;
  }
}

@mixin fonts__h4-underline {
  @include fonts__h4;

  letter-spacing: 0.06em;
  text-decoration: underline;
}

@mixin fonts__h4-all-caps {
  @include fonts__h4;

  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@mixin fonts__m-h4-all-caps-underline {
  @include fonts__h4;

  letter-spacing: 0.06em;
  text-transform: uppercase;
  text-decoration: underline;
}

@mixin fonts__h5 {
  @include fonts__sans-serif-medium;

  font-size: 18px;
  line-height: 1.4;

  @include media('>tablet-landscape') {
    font-size: 20px;
    line-height: 1.55;
  }
}

@mixin fonts__h5-underline {
  @include fonts__h5;

  line-height: 1.55;
  text-decoration: underline;
}

@mixin fonts__h5-all-caps {
  @include fonts__h5;

  line-height: 1.55;
  text-transform: uppercase;
  letter-spacing: 0.06em;
}

@mixin fonts__h5-all-caps-underline {
  @include fonts__h5-all-caps;

  text-transform: uppercase;
  text-decoration: underline;
}

@mixin fonts__h6 {
  @include fonts__sans-serif-medium;

  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 14px;

  @include media('>tablet-landscape') {
    font-size: 14px;
  }
}

@mixin fonts__h6-underline {
  @include fonts__h6;

  font-size: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: underline;
}

@mixin fonts__h6-title-case {
  @include fonts__h6;

  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: none;
}

@mixin fonts__quote {
  @include fonts__serif-italic;

  font-size: 20px;
  line-height: 1.5;

  @include media('>tablet-landscape') {
    font-size: 24px;
    line-height: 1.5;
  }
}

@mixin fonts__statement {
  @include fonts__sans-serif-book;

  font-size: 18px;
  line-height: 1.5;

  @include media('>tablet-landscape') {
    font-size: 20px;
    line-height: 1.5;
  }
}

@mixin fonts__p {
  @include fonts__sans-serif-book;

  font-size: 16px;
  line-height: 1.5;

  @include media('>tablet-landscape') {
    font-size: 18px;
    line-height: 1.5;
  }
}

@mixin fonts__p-underline {
  @include fonts__sans-serif-book;

  text-decoration: underline;
  font-size: 16px;
  line-height: 1.5;

  @include media('>tablet-landscape') {
    font-size: 18px;
    line-height: 1.5;
  }
}

@mixin fonts__caption {
  @include fonts__sans-serif-book-italic;

  font-size: 14px;
  line-height: 1.5;

  @include media('>tablet-landscape') {
    font-size: 16px;
    line-height: 1.5;
  }
}

@mixin fonts__caption-underline {
  @include fonts__sans-serif-book-italic;

  text-decoration: underline;

  /* todo missing style */
  @include media('>tablet-landscape') {
    font-size: 16px;
    line-height: 1.5;
  }
}

@mixin fonts__numerals {
  @include fonts__serif;

  font-size: 36px;

  @include media('>tablet-landscape') {
    font-size: 56px;
  }
}

@mixin fonts__link-small {
  font-family: $fonts__font-family--sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

// mixins for PDF page

@mixin fonts__pdf-h1 {
  font-family: $fonts__font-family--arial;
  font-size: 14px;
  font-weight: $fonts__font-weight--regular;
  line-height: 1.4;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@mixin fonts__pdf-h2 {
  font-family: $fonts__font-family--arial;
  font-size: 10px;
  font-weight: $fonts__font-weight--regular;
  line-height: 1.5;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@mixin fonts__pdf-h3 {
  font-family: $fonts__font-family--arial;
  font-size: 8px;
  font-weight: $fonts__font-weight--regular;
  line-height: 1.5;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@mixin fonts__pdf-h4 {
  font-family: $fonts__font-family--arial;
  font-size: 7px;
  font-weight: $fonts__font-weight--regular;
  line-height: 1.5;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@mixin fonts__pdf-p {
  font-family: $fonts__font-family--arial;
  font-size: 9px;
  font-weight: $fonts__font-weight--regular;
  line-height: 1.5;
}

@mixin fonts__pdf-p-all-caps {
  @include fonts__pdf-p;

  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@mixin fonts__pdf-p-7 {
  @include fonts__pdf-p;

  font-size: 7px;
}

@mixin fonts__pdf-quote {
  font-family: $fonts__font-family--georgia;
  font-size: 12px;
  font-style: italic;
  font-weight: $fonts__font-weight--regular;
  line-height: 1.5;
}
