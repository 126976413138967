@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.label {
  @include fonts__h1;
}

.columns {
  @include media('>=tablet-landscape') {
    columns: 2;
  }
}
