@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  text-align: center;
}

.description {
  @include fonts__statement;

  margin-bottom: 1rem;
  display: block;

  @include media('>=tablet-landscape') {
    margin-bottom: 2rem;
  }
}
