@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  display: block;
}

.icon {
  height: 1.5rem;
  width: 1.5rem;

  @include transition(fill);

  &:hover {
    fill: var(--colors__gold);
  }
}
