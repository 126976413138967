@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.base-results {
  @include search-fade-in;
}

.stats-container {
  display: flex;
  gap: 24px;
}

.stats-container-column {
  flex-direction: column;
}

.stats-container-row {
  flex-direction: column;

  @include media('>=tablet-landscape') {
    flex-direction: row;
    justify-content: space-between;
  }
}

.result-stats-row {
  display: flex;
  align-items: center;
}

.query-correction {
  margin-top: 16px;

  & > em {
    font-weight: var(--font-weight__medium);
  }
}
