@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  display: none;

  @include media('>=tablet-landscape') {
    display: block;
  }
}

.letters {
  display: flex;
  justify-content: space-between;
}

.letter {
  @include transition(color, border);

  padding: 0.5rem;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid var(--colors__gold);
    cursor: pointer;
  }

  &.disabled {
    border: 1px solid transparent;
    color: var(--colors__gray-1);
    cursor: default;
  }
}
