@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  --media-grid-aspect-ratio: 0.88;

  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;

  @include media('>=tablet-portrait') {
    &:last-of-type:nth-of-type(odd) {
      --media-grid-aspect-ratio: 1.9195;

      grid-column: 1 / -1;
    }
  }

  @include target-pdf {
    display: block;
  }
}

.caption {
  @include fonts__caption;

  padding: 0 24px;

  @include target-pdf {
    @include fonts__pdf-p;

    margin-top: var(--pdf__text-spacing-y);
  }
}
