@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.note {
  margin-top: 50px;
  padding: 20px;
  background-color: var(--colors__white);
  border: 2px solid var(--colors__gold);
}

.header {
  margin-bottom: 0;
}
