@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 1.5rem 0;
}

.street-address {
  @include fonts__h5;

  &[href] {
    @include underline;
  }

  margin-bottom: 2rem;
}

.street-address-text {
  white-space: pre-line;
}

.label {
  @include fonts__h6;

  margin-bottom: 1rem;
}

.phone {
  @include underline;

  margin-bottom: 1rem;
}
