@import "src/styles/sass-resources/sass-resources";
@import './../../../../../styles/sass-resources/sass-resources';

.main {
  position: relative;
}

.wrapper {
  position: absolute;
  top: 0;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  background: var(--colors__white);
  z-index: 3;
  transform: translateY(-100vh);
  visibility: hidden;

  @include transition(transform, visibility);

  &.open {
    transform: translateY(0);
    visibility: visible;
  }

  &.visible {
    visibility: visible;
  }

  @include media('>=tablet-landscape') {
    display: none;
  }
}

.inner {
  height: 100%;
  max-height: calc(100vh - var(--NavigationTop__height));
  overflow: auto;
}

.link-content {
  padding: 1rem;
}

.nav-link {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;

  &__text {
    margin-right: 1rem;

    @include fonts__link-small;
  }

  &__icon {
    width: 7px;
    height: auto;
    margin-left: 1rem;
  }
}

.locale-label {
  margin-bottom: 1rem;

  @include fonts__h6;
}

.locales {
  display: flex;
  flex-flow: row wrap;
}

.locale {
  margin-bottom: 1rem;

  a {
    @include underline;
  }

  &:not(:last-child)::after {
    content: '|';
    margin: 0 1rem;
  }
}

.subnav-panel {
  position: fixed;
  padding: 2rem;
  padding-bottom: calc(2rem + var(--NavigationTop__height, 0));
  top: 0;
  left: 0%;
  width: 100%;
  height: calc(100% - var(--NavigationTop__height, 0));
  background: var(--colors__white);
  transform: translate(100%, 0%);
  overflow: auto;
  visibility: hidden;

  @include transition(transform, visibility);

  &.active {
    transform: translate(0%, 0%);
    visibility: visible;
  }
}

.subnav-links {
  margin-bottom: 4rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.subnav-link {
  @include fonts__h6;

  display: block;
  margin-bottom: 2rem;
}

.back-link {
  display: flex;
  margin-bottom: 3rem;

  svg {
    width: 7px;
    transform: rotate(180deg);
    margin-right: 1rem;
  }
}

.nested-text {
  pointer-events: none;
}
