@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.pdf-layout {
  @include target-pdf {
    display: flex;
    align-items: flex-start;
  }
}

.pdf-image-container {
  @include target-pdf {
    $image-width: 142px;
    $image-width-to-height-ratio: 644 / 680;
    $height: $image-width / $image-width-to-height-ratio;
    $gap: 16px;

    position: relative;
    height: $height;
    width: $image-width;
    margin-right: $gap;

    + .content {
      width: calc(100% - #{$image-width + $gap});
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.role-secondary {
  @include fonts__h6;

  margin-bottom: 12px;

  @include media('>=PeopleDetailLayout__two-column') {
    margin-bottom: 1rem;
  }

  @include target-pdf {
    margin-bottom: 8px;

    @include fonts__pdf-h3;
  }
}

.name {
  @include fonts__h2;

  margin-bottom: 1rem;

  @include target-exp-editor {
    display: flex;
    flex-direction: column;
  }

  @include target-pdf {
    margin-bottom: 8px;

    @include fonts__pdf-h1;
  }
}

.role {
  @include fonts__h4-all-caps;

  + .role-secondary {
    margin-top: 8px;
  }

  + .name {
    margin-top: 1rem;

    @include target-pdf {
      margin-top: 8px;
    }
  }

  @include target-pdf {
    @include fonts__pdf-h2;
  }
}

.practices {
  @include fonts__h6;

  @include target-pdf {
    margin-bottom: 8px;

    @include fonts__pdf-h3;
  }
}

.spacer {
  --PeopleDetailHeroRight__spacer: 12px;

  padding-top: var(--PeopleDetailHeroRight__spacer);
  margin-top: var(--PeopleDetailHeroRight__spacer);
  position: relative;

  &:empty {
    display: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    width: 50px;
    background-color: var(--colors__gray-1);
  }

  @include media('>=PeopleDetailLayout__two-column') {
    --PeopleDetailHeroRight__spacer: 1rem;
  }

  @include target-pdf {
    --PeopleDetailHeroRight__spacer: 8px;

    margin-top: 0;
  }
}

.links {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include target-pdf {
    display: block;
  }
}

.link-item {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2px 16px;

  @include target-pdf {
    gap: 0;
  }
}

.link {
  @include fonts__h6;

  &[href] {
    @include fonts__h6-underline;
    @include underline;
  }

  @include target-pdf {
    @include fonts__pdf-h3;

    padding: 2px;

    &:not(.email-link) {
      text-decoration: none;
    }
  }
}

.email {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.share-spacer {
  @include target-pdf {
    display: none;
  }
}

.share {
  display: flex;
  gap: 1rem;

  @include target-exp-editor {
    min-width: 150px;
  }
}

.item {
  &:empty {
    display: none;
  }
}
