// This mixin has the same usage as "@include media" except it outputs "@container" instead of "@media".
// This code was copied from the "include-media" npm package (node_modules/include-media/src/_media.scss)
// and then modified by replacing the word "media" with the word "container" in the appropriate places.

@use 'sass:list';
@use 'sass:string';

@mixin container-query($conditions...) {
  @if ($im-media-support and list.length($conditions) == 0) or
    (not $im-media-support and im-intercepts-static-breakpoint($conditions...))
  {
    @content;
  } @else if $im-media-support and list.length($conditions) > 0 {
    @container #{string.unquote(parse-expression(list.nth($conditions, 1)))} {
      // Recursive call
      @include container-query(slice($conditions, 2)...) {
        @content;
      }
    }
  }
}
