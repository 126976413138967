:root {
  --container__padding-x: 24px;

  #pdf {
    --container__padding-x: 0;
  }
}

.container-6 {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--container__max-width, calc(696px + var(--container__padding-x) * 2));
  padding-left: var(--container__padding-x);
  padding-right: var(--container__padding-x);
}

.container-8 {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--container__max-width, calc(922px + var(--container__padding-x) * 2));
  padding-left: var(--container__padding-x);
  padding-right: var(--container__padding-x);
}

.container-10 {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--container__max-width, calc(1158px + var(--container__padding-x) * 2));
  padding-left: var(--container__padding-x);
  padding-right: var(--container__padding-x);
}

.container-12 {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--container__max-width, calc(1392px + var(--container__padding-x) * 2));
  padding-left: var(--container__padding-x);
  padding-right: var(--container__padding-x);
}

.container-full-width {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--container__max-width, calc(1392px + var(--container__padding-x) * 2));
}

.container-full-width-1920 {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--container__max-width, 1920px);
}
