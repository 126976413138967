@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.social-link {
  margin: 0 0.5rem;
}

.wechat-qr {
  position: relative;

  &:hover {
    --SocialLinks__opacity: 1;
  }
}

.wechat-qr-code {
  background-color: var(--colors__white);
  position: absolute;
  display: block;
  bottom: calc(100% + 0.5rem);
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  width: 150px;
  max-width: none;
  pointer-events: none;
  opacity: var(--SocialLinks__opacity, 0);

  @include transition(opacity);
}
