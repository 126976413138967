@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.job-grid {
  display: grid;
  grid-template-columns: 1;

  @include media('>=tablet-portrait') {
    grid-template-columns: 1fr 1fr;
  }
}

.job {
  display: block;
  padding: 2rem;
  text-align: center;
  border-bottom: 1px solid var(--colors__gray-1);
  position: relative;

  @include media('<=tablet-portrait') {
    &:last-child {
      border-bottom: 0;
    }
  }

  @include media('>=tablet-portrait') {
    padding: 4rem 2rem;

    &:nth-child(odd) {
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 100%;
        width: 1px;
        height: calc(100% - 4rem);
        transform: translateY(-50%);
        background-color: var(--colors__gray-1);
      }
    }

    &--last-row {
      border-bottom: 0;
    }
  }
}

.title {
  display: block;

  @include fonts__h4-all-caps;
}

.city {
  display: block;

  @include fonts__p;

  margin-top: 1rem;
}
