@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: column;
  gap: 21px;
  text-align: center;

  @include target-pdf {
    display: block;
  }
}

.header {
  @include fonts__h2;

  @include target-pdf {
    @include fonts__pdf-h2;

    padding: var(--pdf__text-spacing-y);
    margin-bottom: var(--pdf__text-spacing-y);
  }
}

.text {
  @include fonts__p;

  text-align: left;
}

.line {
  height: 1px;
  width: 70%;
  margin: 27px auto 0; // 48px (margin-bottom in .spacer) - 21px (gap in .main) = 27px
  background-color: var(--colors__gray-1);
}
