@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  @include media('<tablet-landscape') {
    --container__padding-x: 0;
  }
}

.module-header {
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  @include target-pdf {
    padding: 0;
    border: none;
    text-align: left;

    @include fonts__pdf-h3;
  }
}

.toggle {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  position: relative;

  @include target-pdf {
    margin-bottom: 0;
    display: block;
  }
}

.icon {
  right: 0;
  top: calc(50% - 0.5rem);
  transform: translateY(-50%);
  position: absolute;

  @include target-pdf {
    display: none;
  }
}

.content {
  display: grid;
  grid-template-rows: 0fr;

  @include transition(grid-template-rows);

  &--expanded {
    grid-template-rows: 1fr;
  }

  @include target-pdf {
    grid-template-rows: 1fr;
  }

  @include target-print {
    grid-template-rows: 1fr;
  }
}

.wrapper {
  overflow: hidden;
}

.body {
  padding-bottom: 1rem;
  text-align: left;

  @include media('>=tablet-landscape') {
    padding-bottom: 2rem;
  }
}
