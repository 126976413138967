@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  text-align: center;
  padding: 48px 20px;
  margin: 0 auto;
  border-bottom: 1px solid var(--colors__gray-2);

  @include media('>=tablet-portrait') {
    gap: 24px;
    padding: 64px 0;
  }

  @include target-pdf {
    display: block;
    padding: 0;
    padding-bottom: var(--pdf__section-spacing);
  }
}

.title,
.attributions-container {
  max-width: 1200px;
}

.date {
  @include fonts__h6;

  @include target-pdf {
    @include fonts__pdf-h3;
  }
}

.title {
  @include fonts__h2;

  @include target-pdf {
    @include fonts__pdf-h1;

    margin: 1rem;
  }
}

.attributions-container-false,
.attributions-list {
  @include fonts__h5-all-caps;

  display: flex;
  justify-content: center;
  flex-direction: column;
  column-gap: 0.5em;
  align-items: center;

  @include media('>=tablet-portrait') {
    flex-flow: row wrap;
  }

  @include target-pdf {
    gap: 0;
    flex-flow: row wrap;
  }
}

.split-format {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include media('>=tablet-portrait') {
    justify-content: center;
    flex-direction: row;
  }

  @include target-pdf {
    gap: 0;
    flex-direction: row;
    justify-content: center;
  }
}

.attributions-container-true,
.hosts-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}

.divider {
  background-color: var(--colors__black);
  width: 1px;

  @include media('<tablet-portrait') {
    width: 50%;
    height: 1px;
    margin: 0 auto;
  }

  @include target-pdf {
    height: auto;
    width: 1px;
    margin: 0 1rem;
  }
}

.attribution-label,
.host-label {
  @include fonts__h5-all-caps;

  @include target-pdf {
    @include fonts__pdf-h3;

    padding: 2px;
  }
}

.hosts-value {
  @include fonts__h5-all-caps;

  @include target-pdf {
    @include fonts__pdf-h3;
  }
}

.attributions-list-item {
  position: relative;

  @include target-pdf {
    @include fonts__pdf-h3;

    padding: 2px;
  }
}

a.source__link,
a.attributions-list-item__link {
  @include underline;

  @include target-pdf {
    text-decoration: none;
  }
}

.links {
  margin-top: 1rem;

  @include target-pdf {
    margin-top: 0;
  }
}

.source {
  @include fonts__caption;

  margin-top: 1.5rem;

  @include target-pdf {
    @include fonts__pdf-p-7;

    margin-top: 1rem;
  }
}

.pdf {
  @include fonts__h6;
  @include reverse-underline;

  text-align: center;

  @include target-pdf {
    display: none;
  }
}

.registration-cta {
  margin-top: 12px;

  @include media('>=tablet-portrait') {
    margin-top: 32px;
  }

  @include target-pdf {
    display: none;
  }
}
