@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.headline {
  @include fonts__h5-all-caps;
}

.headline__noIcon {
  // 50px for icon + 1rem for gap
  padding-top: 4.125rem;
}

.icon {
  --Image__object-fit: contain;
}
