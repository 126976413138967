@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.results-header {
  display: flex;
  margin-top: 1.5rem;
}

.sort-container {
  display: flex;
  padding-right: 0.25rem;

  @include fonts__h6;
}

.sort {
  padding: 0 5px 10px;
}

.selected {
  border-bottom: 2px solid #000;
  color: #000 !important;
  font-weight: 600;
}
