@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1.5rem;
  text-align: center;
  width: 100%;
  background: var(--colors__gray-1b);
}

.eyebrow {
  @include fonts__h4-all-caps;
}

.number {
  @include fonts__numerals;
}

.description {
  text-align: center;

  @include fonts__h5;
}

.body {
  &::before,
  &::after {
    content: '';
    display: inline-block;
    height: 1.5rem;
    width: 1px;
    background: var(--colors__black);
    margin-top: 0.5rem;
  }

  @include media('>=tablet-landscape') {
    &::before,
    &::after {
      height: 3rem;
    }
  }
}
