@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.jobs {
  display: flex;
  justify-content: center;
  flex-direction: column;

  @include media('>=tablet-landscape') {
    flex-direction: row;
  }
}

.title {
  display: block;

  @include fonts__h5-all-caps;

  margin-bottom: 1rem;
}

.job {
  padding: 6rem 2rem;
}

.view-all {
  display: flex;
  justify-content: center;
}
