@mixin target-devices-with-hover {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin target-devices-without-hover {
  @media (hover: none) {
    @content;
  }

  @media (hover: hover) and (pointer: coarse) {
    @content;
  }
}

@mixin target-exp-editor {
  // :global(#exp-editor) required since #exp-editor is a global id
  :global(#exp-editor) & {
    @content;
  }

  #exp-editor & {
    @content;
  }
}

@mixin target-pdf {
  :global(#pdf) & {
    @content;
  }

  #pdf & {
    @content;
  }
}

@mixin target-print {
  @include media('print') {
    :global(#__next) & {
      @content;
    }
  }
}
