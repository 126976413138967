@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.agenda-list {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include target-pdf {
    display: block;
  }
}

.agenda-list-item {
  @include target-pdf {
    &:not(:first-child) {
      margin-top: var(--pdf__text-spacing-y);
    }
  }
}
