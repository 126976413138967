@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  --RichText__spacing-y: 1rem;

  @include target-pdf {
    --RichText__spacing-y: var(--pdf__text-spacing-y);
  }

  @include fonts__p;

  @include target-pdf {
    @include fonts__pdf-p;
  }

  h1 {
    @include fonts__h1;

    @include target-pdf {
      @include fonts__pdf-h1;
    }
  }

  h2 {
    @include fonts__h2;

    @include target-pdf {
      @include fonts__pdf-h2;
    }
  }

  h3 {
    @include fonts__h3-all-caps;

    @include target-pdf {
      @include fonts__pdf-h3;
    }
  }

  h4 {
    @include fonts__h4-all-caps;

    @include target-pdf {
      @include fonts__pdf-h3;
    }
  }

  h5 {
    @include fonts__h5-all-caps;

    @include target-pdf {
      @include fonts__pdf-h3;
    }
  }

  h6 {
    @include fonts__h6;

    @include target-pdf {
      @include fonts__pdf-h3;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:not(:first-child) {
      margin-top: 1em;
    }
  }

  p {
    margin-top: var(--RichText__spacing-y);
  }

  a {
    @include underline;
  }

  ul,
  ol {
    margin-left: var(--RichText__list-margin-left, 2rem);
    margin-top: var(--RichText__spacing-y);
    padding: 0;

    @include target-pdf {
      margin-left: var(--RichText__list-margin-left, 1rem);
    }
  }

  ul li {
    list-style-type: var(--RichText__list-style-type, disc);
  }

  ol li {
    list-style-type: var(--RichText__list-style-type, decimal);
  }

  li:not(:last-child) {
    margin-bottom: var(--RichText__spacing-y);
  }

  img,
  iframe,
  table,
  figure {
    max-width: 100%;
    margin-top: var(--RichText__spacing-y);
  }

  img {
    height: auto;
  }

  iframe {
    @include target-pdf {
      display: none;
    }
  }

  blockquote {
    @include fonts__quote;

    margin: 2rem 1rem;
    text-align: center;

    @include media('>=tablet-portrait') {
      margin: 2rem;
    }

    @include target-pdf {
      @include fonts__pdf-quote;

      margin: var(--pdf__text-spacing-y);
    }
  }

  cite {
    display: block;
    margin-top: var(--RichText__spacing-y);
    padding-top: 0.5rem;
    text-align: center;

    @include fonts__h6;

    @include target-pdf {
      @include fonts__pdf-h3;
    }
  }

  > * {
    &:first-child {
      margin-top: 0;
    }
  }

  @include target-pdf {
    // This targets the div wrapper around the iframe that is used for the podcast player
    div[data-aspectratio][data-mobile-aspectratio] {
      display: none;
    }
  }
}
