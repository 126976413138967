@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.insights-card {
  justify-content: flex-start;
}

.insights-list {
  display: grid;
  gap: 64px;
  grid-template-columns: repeat(auto-fill, minmax(var(--cards_max-width, 336px), 1fr));
  grid-auto-rows: auto;
  justify-content: center;
}
