@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.grid {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, 336px);
  gap: 64px;
}

.card {
  display: flex;
  justify-content: center;
  position: relative;
}
