@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

$breakpoint-2-col: '>=500px';
$breakpoint-3-col: '>=tablet-portrait';
$breakpoint-4-col: '>=tablet-landscape';

.main {
  --BasicContentSimpleStatStack__border-style: 1px solid var(--colors__gray-2);

  container-type: inline-size;
}

.content {
  background-color: var(--colors__gray-1b);
  padding: 2rem 1.5rem;
}

.header {
  @include fonts__h4-all-caps;

  text-align: center;
  margin-bottom: 2rem;
  padding: 0 1.5rem;
}

.stat {
  width: var(--BasicContentSimpleStatStack__width, 100%);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    background-color: var(--colors__gray-2);
    height: calc(100% - 3rem);
    width: 1px;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    opacity: var(--BasicContentSimpleStatStack__right-border-opacity, 0);
  }

  &:not(:last-of-type) {
    border-bottom: var(--BasicContentSimpleStatStack__border-style);

    @include container-query($breakpoint-2-col) {
      border-bottom: none;

      --BasicContentSimpleStatStack__right-border-opacity: 1;
    }
  }
}

.stats {
  @include container-query($breakpoint-2-col) {
    --BasicContentSimpleStatStack__width: 50%;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &--3 {
    @include container-query($breakpoint-2-col, '<tablet-portrait') {
      .stat--1,
      .stat--2 {
        border-bottom: var(--BasicContentSimpleStatStack__border-style);
      }

      .stat--2 {
        --BasicContentSimpleStatStack__right-border-opacity: 0;
      }
    }

    @include container-query($breakpoint-3-col) {
      --BasicContentSimpleStatStack__width: 33.333%;
    }
  }

  &--4 {
    @include container-query($breakpoint-2-col, '<tablet-landscape') {
      .stat--2 {
        --BasicContentSimpleStatStack__right-border-opacity: 0;
      }

      .stat--1,
      .stat--2 {
        border-bottom: var(--BasicContentSimpleStatStack__border-style);
      }
    }

    @include container-query($breakpoint-4-col) {
      --BasicContentSimpleStatStack__width: 25%;
    }
  }
}
