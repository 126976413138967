@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.wrapper {
  container-type: inline-size;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include container-query('>=tablet-portrait') {
    gap: 75px;
  }
}

.header-container {
  text-align: center;
}

.description {
  margin: 0 auto;

  @include container-query('>=tablet-portrait') {
    max-width: 658px;
  }
}

.cards-container {
  display: grid;
  grid-template-columns: 1fr;

  @include container-query('>=tablet-portrait') {
    row-gap: 0;
    grid-template-columns: repeat(4, 1fr);
  }
}

.item {
  display: flex;
  justify-content: center;
  align-items: stretch;

  &:last-of-type {
    --Content-Border-Bottom: none;
  }

  @include container-query('>=tablet-portrait') {
    grid-area: span 2 / span 2;

    &:last-of-type,
    &.even-cards:nth-last-of-type(2) {
      --Content-Border-Bottom: none;
    }

    &:last-of-type:nth-of-type(odd) {
      grid-column: 2 / 4;
    }

    &:nth-of-type(odd):not(:last-of-type) {
      border-right: 1px solid var(--colors__gray-1);
    }
  }
}
