@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1.5rem;
}

.number {
  @include fonts__numerals;

  word-break: break-all;
}

.description {
  text-align: center;
}
