@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

$bp-two-col: '>=575px';
$bp-one-col: '<575px';

.main {
  &.horizontal-parent {
    justify-content: flex-start;
  }

  &.image-right {
    @include container-query($bp-two-col) {
      flex-direction: row-reverse;
    }
  }
}
