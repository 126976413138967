@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.facet-list {
  @include transition(max-height);

  display: flex;
  flex-direction: column;
  padding: 30px 30px 0;
  background-color: var(--colors__gray-1b);
  overflow: hidden;

  @include media('<tablet-landscape') {
    padding-top: 0;
    margin-bottom: 24px;
  }
}

.facet-list-item {
  width: 100%;
  position: relative;
}
