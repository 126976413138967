@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

$nav-height-with-spacing: 142px;

.main {
  @include media('<PeopleDetailLayout__two-column') {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
  }

  @include media('>=PeopleDetailLayout__two-column') {
    height: 100%;
  }
}

.wrapper {
  @include media('>=PeopleDetailLayout__two-column') {
    @include transition(top);

    position: sticky;
    top: calc(
      var(--PeopleDetailHeroLeft__top, 0) - var(--PeopleDetailHeroLeft__nav-bar-spacing, 0)
    );
    padding-bottom: 2rem;
  }
}

.image-container {
  position: relative;

  // Do not change aspect-ratio unless absolutely required.
  // The image guidelines provided to the client are based on this aspect ratio.
  // Therefore changing it could impact the hundreds of images that were uploaded.
  aspect-ratio: 0.947;
}

.tools {
  margin-top: 14px;

  @include media('>=PeopleDetailLayout__two-column') {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 22px;
  }
}

.links {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  position: relative;
  flex-grow: 1;

  @include media('<PeopleDetailLayout__two-column') {
    justify-content: space-between;
    padding: 0 1.5rem;
  }

  @include media('>=PeopleDetailLayout__two-column', '<tablet-landscape') {
    justify-content: center;
  }
}

.divider {
  width: 1px;
  height: 24px;
  background-color: var(--colors__gray-1);
}

.link {
  @include transition(color);
  @include fonts__h6;

  &--active {
    color: var(--colors__gray-2);
  }
}

.vcard {
  @include fonts__h6-underline;
  @include underline;
}

.share {
  --BasicContentShareTray__flex-direction: row;
  --spacer__margin-bottom: 0;

  @include media('<tablet-landscape') {
    flex-grow: 1;
  }

  @include media('<PeopleDetailLayout__two-column') {
    display: none;
  }
}

.pdf-links-list {
  @include transition(opacity, bottom, top);

  position: absolute;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 10px 0;
  opacity: 0;
  width: 100%;
  top: 100%;

  &--active {
    opacity: 1;
  }
}

.pdf-links-item {
  width: max-content;
  background-color: var(--colors__white);
  pointer-events: none;

  .pdf-links-list--active & {
    pointer-events: all;
  }
}

.arrow {
  transform: rotate(90deg);
  margin-left: 0.5rem;

  @include transition(transform);

  path {
    @include transition(stroke);
  }

  &--active {
    transform: rotate(90deg) rotateY(-180deg);

    path {
      stroke: var(--colors__gray-2);
    }
  }
}
