@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  width: 100%;

  @include media('>=tablet-landscape') {
    flex-direction: row;
    align-items: center;
  }
}

.breadcrumbs {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  flex: 1 auto;
  overflow: hidden;

  @include media('>=tablet-landscape') {
    width: 70%;
  }
}

.breadcrumb {
  margin-right: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  flex-shrink: 0;
  white-space: nowrap;

  &:last-child {
    flex-shrink: 1;

    .text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.link {
  display: flex;
  overflow: hidden;
  padding: 4px;
}

a.link {
  @include underline;

  margin-right: 1rem;

  &:focus {
    @include focus-inset;
  }
}

.languages {
  display: none;

  @include media('>=tablet-landscape') {
    display: block;
  }
}
