@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  display: block;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;

  @include fonts__h3-all-caps;

  margin-bottom: 1rem;

  @include target-pdf {
    @include fonts__pdf-h2;

    padding: var(--pdf__text-spacing-y);
    margin-bottom: var(--pdf__text-spacing-y);
  }
}

.double-line {
  border-top: 1px solid var(--colors__gray-1);
  border-bottom: 1px solid var(--colors__gray-1);

  @include fonts__h4-all-caps;

  @include target-pdf {
    @include fonts__pdf-h3;
  }
}

.single-line {
  @include fonts__h4-all-caps;

  border-bottom: 1px solid var(--colors__gray-1);
}
