@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.people-results {
  width: 100%;
}

.results-list {
  padding-top: 16px;
  width: 100%;
  display: grid;
  column-gap: 16px;
  justify-content: center;

  @include media('>=tablet-portrait') {
    row-gap: 48px;
    grid-template-columns: repeat(auto-fit, var(--cards_max-width, 336px));
    grid-auto-rows: auto;
  }
}

.card-name {
  @include fonts__h4-all-caps;

  text-decoration: underline solid;
  text-decoration-color: var(--colors__gold);
  text-align: center;

  @include media('>=tablet-portrait') {
    @include transition(text-decoration-color);

    text-decoration-color: transparent;
  }
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: var(--cards_max-width, 336px);
  border-bottom: 1px solid var(--colors__gray-1);
  padding: 24px 0;

  @include media('>=tablet-portrait') {
    border-bottom: none;
    padding: 0;
    min-height: 630px;
  }

  @include target-devices-with-hover {
    &:hover {
      @include ImageCtaHover;

      .card-name {
        text-decoration-color: var(--colors__gold);
      }
    }
  }

  @include target-devices-without-hover {
    @include ImageCtaHover;

    .card-name {
      text-decoration-color: var(--colors__gold);
    }
  }
}

.card-image {
  width: 250px;
  height: 312px;

  img {
    object-position: top;
  }

  @include media('>=tablet-portrait') {
    width: var(--cards_max-width, 336px);
    height: 420px;
  }
}

.card-content {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  position: relative;
  width: 100%;

  & a:not(.card-link) {
    @include underline;
  }
}

.card-link {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;

  &:focus-visible {
    outline-offset: -0.25rem;

    & ~ .card-name {
      text-decoration-color: var(--colors__gold);
    }
  }
}

.card-title {
  @include fonts__h6;

  text-align: center;
}

.card-offices {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.card-offices-item {
  display: flex;
  justify-content: center;
  gap: 8px;
  flex-wrap: wrap;
}

.card-email {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card-office,
.card-phone,
.card-email {
  @include fonts__h6;

  text-align: center;
  position: relative;
  z-index: 2;
}

.card-excerpt {
  @include fonts__caption;

  text-align: center;
}
