@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

$section-padding: 0 var(--container__padding-x);

.main {
  padding-top: 50px;
  display: grid;
  grid-template-columns: 1fr;

  @include media('>=tablet-landscape') {
    grid-template-columns: 1fr 2fr;
    column-gap: 70px;
    row-gap: 50px;
  }
}

.search-form {
  display: contents;
}

.search-header {
  @include fonts__h2;

  text-align: center;
  grid-row: 1 / 2;
  grid-column: 1 / -1;

  @include media('<tablet-landscape') {
    margin-bottom: 24px;
  }
}

.search-box-wrapper {
  grid-row: 2 / 3;
  grid-column: 1 / -1;
  width: 100%;
}

.search-box {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;

  @include media('<tablet-landscape') {
    max-width: none;
    margin-bottom: 24px;
  }
}

.section-facet-wrapper {
  padding: $section-padding;
  grid-row: 3 / 4;
  grid-column: 1 / -1;
}

.results {
  padding: $section-padding;

  @include media('>=tablet-landscape') {
    width: 100%;
    max-width: 934px;
    margin-right: auto;
    padding-left: 0;
  }
}

.facets-wrapper {
  padding: $section-padding;

  @include media('>=tablet-landscape') {
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    padding-right: 0;
  }
}

.no-results {
  margin-top: 24px;
}
