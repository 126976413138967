@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

@mixin right-border {
  &::after {
    content: '';
    position: absolute;
    top: var(--Highlights__top, 50%);
    left: 100%;
    width: 1px;
    height: var(--Highlights__height, calc(100% - var(--Highlight__spacing) * 2));
    transform: var(--Highlights__transform, translateY(-50%));
    background-color: var(--colors__gray-1);
  }
}

.highlight-container {
  position: relative;

  &:not(:last-child) {
    border-bottom: var(--Highlight__border-bottom, solid var(--colors__gray-1));
  }
}

.highlights {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;

  --Highlight__spacing: 2rem;

  @include media('>=tablet-landscape') {
    &--one {
      grid-template-columns: 50%;
    }

    &--two {
      grid-template-columns: 1fr 1fr;

      .highlight-container:nth-child(2n + 1) {
        @include right-border;
      }
    }

    &--three {
      grid-template-columns: 1fr 1fr 1fr;

      .highlight-container:nth-child(3n + 1),
      .highlight-container:nth-child(3n + 2) {
        @include right-border;
      }
    }

    .highlight-container--last-row {
      --Highlight__border-bottom: 0;
      --Highlight__margin-bottom: 0;

      &::after {
        --Highlights__top: var(--Highlight__spacing);
        --Highlights__transform: none;
      }
    }
  }
}

.highlight {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 1rem;

  &::after {
    @include animated-gold-line;
  }

  &:hover,
  &:focus-visible {
    @include gold-swipe-hover;
  }
}
