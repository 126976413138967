@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.details {
  display: grid;
  background: var(--colors__gray-1b);
  padding: 0 2rem;

  @include media('>=tablet-landscape') {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    padding: 3rem 1rem;
  }
}

.partners {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    background: var(--colors__gray-2);
    margin: 0 auto 2rem;
  }

  @include media('>=tablet-landscape') {
    &::before {
      width: 1px;
      height: 100%;
      margin-bottom: 0;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;

  @include media('>=tablet-portrait') {
    flex-direction: row;
    justify-content: space-between;
  }
}

.container-item {
  width: 100%;

  @include media('>=tablet-landscape') {
    width: 50%;
    max-width: 50%;

    &:only-child {
      margin: 0 auto;
    }
  }
}

.hero {
  padding: 2rem;

  @include media('>=tablet-landscape') {
    padding: 5rem;
  }
}

.label {
  @include fonts__h6;

  margin-top: 2rem;
  text-align: center;

  @include media('>=tablet-landscape') {
    margin-top: 0;
  }
}

.image {
  position: relative;
  height: 250px;
  display: block;

  @include media('>=tablet-landscape') {
    height: 330px;
  }
}
