@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  display: grid;
  grid-template-columns: 1fr;
  gap: 23px;

  @include media('>=tablet-portrait') {
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
  }

  @include target-pdf {
    $spacing: 1rem;

    display: block;

    &::after {
      content: '';
      display: table;
      clear: both;
    }

    > * {
      width: calc(50% - #{$spacing / 2});
      float: left;

      &:nth-child(2n) {
        margin-left: $spacing;
      }

      &:nth-child(n + 3) {
        padding-top: $spacing;
      }

      &:nth-child(odd):last-child {
        margin-left: auto;
        margin-right: auto;
        float: none;
        clear: both;
      }
    }
  }
}
