@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  gap: 64px;
  flex-direction: column;
  align-items: center;
}

.grid {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: 75px;
  row-gap: 64px;

  @include media('>=tablet-portrait') {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
  }
}

.item {
  display: flex;
  justify-content: center;
  position: relative;
  height: auto;
  width: 100%;
  max-width: var(--cards_max-width, 336px);
}

.content-card {
  justify-content: flex-start;
  max-width: 100%;
}
