@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.file-name {
  @include fonts__link-small;
  @include underline;

  font-size: 18px;
  line-height: 155%;
  text-transform: capitalize;

  @include media('<tablet-portrait') {
    text-align: center;
    display: block;
  }

  @include target-pdf {
    @include fonts__pdf-p;
  }
}
