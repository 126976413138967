@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  position: relative;
}

.homepage {
  &::after {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgb(0 0 0 / 60%);
    z-index: 0;
  }
}

.images-loaded {
  --Overlay-Opacity: 0;
}

.no-images {
  background: var(--colors__gray-3);
}

.images {
  height: 100%;
  position: absolute;
  width: 100%;
  display: flex;
  left: -50%;
  transform: translateX(50%);
  z-index: 1;
}

.image {
  flex-grow: 1;
  position: relative;
  height: 100%;

  &.overlay {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: var(--Overlay-Opacity, 1);

      @include transition(opacity);
    }
  }
}

.two {
  img {
    // fix issue where there could be a 1px gap
    // between the two hero images when zoomed in.
    // This is making the right image overlap the left image
    // by 1px to prevent the possibility of a gap.
    left: -1px !important;
    width: calc(100% + 1px) !important;
    max-width: none;
  }
}

.content {
  padding: 1.5rem 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 400px;
  color: var(--colors__white);
  text-align: center;
  z-index: 1;

  .homepage & {
    min-height: 100vh;
    padding: 1.5rem 0 4rem;
  }

  @include media('>=tablet-landscape') {
    padding: 3rem 0;
    min-height: 430px;
  }
}

.intro {
  @include fonts__h1;
}

.header-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .homepage & {
    margin-top: var(--NavigationHeader__height, 0);
  }
}

.eyebrow {
  @include fonts__h5-all-caps;

  margin-bottom: 1rem;
}

.headline {
  @include fonts__h1;
}

.description {
  @include fonts__statement;

  margin-top: 1.25rem;
  width: 75%;
}

.cta {
  --BoxCta__border-color: var(--colors__white);

  margin-top: 1rem;
}

.logo-wrapper {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  width: 300px;

  @include media('>=tablet-landscape') {
    width: 400px;
  }

  &.has-played {
    opacity: 0;
  }
}

.scroll-container {
  --FadeInContainer__initial-y: 0;

  position: absolute;
  bottom: 1rem;
  text-align: center;
}

.scroll-button {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include transition(transform);

  &:hover {
    transform: translateY(0.5rem);
  }
}

.scroll-label {
  @include fonts__h6;
}

.scroll-icon {
  transform: rotate(90deg);
  width: 1rem;

  path {
    stroke: var(--colors__white);
  }
}
