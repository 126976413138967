@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  @include fonts__h5-all-caps;
  @include reverse-underline;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.icon-container {
  @include transition(transform);

  transform: translateY(0);

  .button:hover & {
    transform: translateY(-8px);
  }
}
