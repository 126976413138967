$underline-active-color: var(--underline__color, var(--colors__gold));
$underline-inactive-color: var(--underline__color, transparent);

@mixin underline {
  text-decoration: underline solid;
  text-decoration-color: $underline-active-color;

  @include transition(text-decoration-color);

  @include target-devices-with-hover {
    &:hover {
      text-decoration-color: $underline-inactive-color;
    }
  }
}

@mixin underline-active($className: null) {
  @if $className {
    #{$className} {
      --underline__color: transparent;
    }
  } @else {
    --underline__color: transparent;
  }
}

@mixin reverse-underline {
  text-decoration: underline solid;

  @include transition(text-decoration-color);

  @include target-devices-with-hover {
    text-decoration-color: $underline-inactive-color;

    &:hover {
      text-decoration-color: $underline-active-color;
    }
  }

  @include target-devices-without-hover {
    text-decoration-color: $underline-active-color;
  }
}

@mixin reverse-underline-active($className: null) {
  @if $className {
    #{$className} {
      --underline__color: var(--colors__gold);
    }
  } @else {
    --underline__color: var(--colors__gold);
  }
}

@mixin swipe-underline-active {
  &::after {
    transform: scale(var(--UnderlineCta__scale-hover));
  }
}

@mixin swipe-underline {
  position: relative;

  &::after {
    content: '';
    background: var(--colors__gold);
    position: absolute;
    bottom: -4px;
    left: 0;
    height: 2px;
    width: 100%;
    transform: scale(var(--UnderlineCta__scale-default));
    pointer-events: none;

    @include transition(transform);
  }

  &:hover,
  &:focus-visible {
    @include swipe-underline-active;
  }
}

@mixin swipe-underline-on-hover {
  --UnderlineCta__scale-default: 0;
  --UnderlineCta__scale-hover: 1;

  @include swipe-underline;
}

@mixin swipe-by-default {
  --UnderlineCta__scale-default: 1;
  --UnderlineCta__scale-hover: 0;

  @include swipe-underline;
}
