@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

$bp-desktop: '>=tablet-portrait';

.main {
  container-type: inline-size;
}

.wrapper {
  display: flex;
  gap: 20px;
  flex-direction: column;

  @include container-query($bp-desktop) {
    flex-direction: row;
  }
}

.image-box {
  position: relative;
  aspect-ratio: 1.3;

  @include container-query($bp-desktop) {
    width: 50%;

    &--right {
      order: 1;
    }
  }
}

.content {
  padding: 40px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  border: 1px solid var(--colors__gray-1);
  border-left: none;
  border-right: none;

  @include container-query($bp-desktop) {
    padding: 40px;
    width: 50%;
  }
}

.headline {
  @include fonts__h3-all-caps;

  color: var(--colors__black);
}

.body {
  @include fonts__p;

  color: var(--colors__black);
}

.cta {
  align-self: center;
}
