@import "src/styles/sass-resources/sass-resources";
@import './../../../../styles/sass-resources/sass-resources';

.heading {
  @include fonts__h5-all-caps;

  margin-bottom: 1rem;
}

.wrapper {
  display: grid;
  grid-template-rows: var(--ServicesRelatedList__rows, 0fr);
  margin-top: var(--RichText__spacing-y, 16px);

  @include transition(grid-template-rows);

  &[aria-hidden='false'] {
    --ServicesRelatedList__rows: 1fr;
  }

  &[aria-hidden='true'] {
    --ServicesRelatedList__visibility: hidden;
    --ServicesRelatedList__transition-delay: var(--transition-duration);
  }

  @include target-print {
    --ServicesRelatedList__rows: 1fr;
    --ServicesRelatedList__visibility: visible;
  }
}

.content {
  overflow: hidden;
  visibility: var(--ServicesRelatedList__visibility);
  transition-delay: var(--ServicesRelatedList__transition-delay);
}

.no-bullets {
  --RichText__list-style-type: none;
  --RichText__list-margin-left: 0;
}

.link {
  @include fonts__link-small;

  text-transform: capitalize;
  text-decoration: underline;
  text-decoration-color: var(--colors__black);
}
