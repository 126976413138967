@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.attachments-list {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 16px;
  row-gap: 24px;

  @include media('>=tablet-portrait') {
    grid-template-columns: auto auto;
    grid-auto-rows: auto;
  }

  @include target-pdf {
    display: block;

    > * {
      &:not(:first-child) {
        margin-top: var(--pdf__text-spacing-y);
      }

      a {
        &::after {
          content: none;
        }
      }
    }
  }
}
