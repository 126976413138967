@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.form-container {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.search-box {
  max-width: 985px;
  margin: 0 auto;
}

.results {
  margin-top: 32px;
}

.content {
  margin-top: 64px;
}
