@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  position: relative;
}

.svg {
  aspect-ratio: 1.5;
  width: 75px;
  height: auto;

  @include media('>=tablet-landscape') {
    width: 120px;
  }
}

$transition-duration: 1s;
$transform-x-start: 25%;
$transform-y-start: $transform-x-start * -2;
$transition-delay: 0.7s;

.left,
.right {
  @include transition(
    transform $transition-duration $transition-delay,
    opacity $transition-duration $transition-delay
  );

  opacity: 0;
}

.left {
  transform: translate($transform-x-start * -1, $transform-y-start);
}

.right {
  transform: translate($transform-x-start, $transform-y-start);
}

.center {
  @include transition(transform $transition-duration $transition-delay);

  transform: translateY(-15%);
}

.transition-complete {
  transform: translate(0, 0);
  opacity: 1;
}
