@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

$people-inputs-max-width: 1089px;

.main {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.people-search-form-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.people-search-box,
.name-search-box,
.alpha-filters,
.alpha-list {
  max-width: $people-inputs-max-width;
  margin: 0 auto;
}

.alpha-filters {
  width: 100%;
  position: relative;
}

.alpha-toggle {
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;

  &:focus {
    @include focus;
  }
}

.alpha-list {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;

  @include media('>=tablet-portrait') {
    max-width: $people-inputs-max-width;
  }
}

.alpha-list-item {
  @include transition(color, border);

  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid transparent;
  height: 22px;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &.focused-alpha,
  &.active-alpha {
    border: 1px solid var(--colors__gold);
    cursor: pointer;
  }

  &.disabled-alpha {
    border: 1px solid transparent;
    color: var(--colors__gray-1);
    cursor: default;
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
}
