@import "src/styles/sass-resources/sass-resources";
@import './../../../../styles/sass-resources/sass-resources';

.main {
  &:hover {
    .image-container[href] {
      @include ImageCtaHover;
    }

    @include reverse-underline-active('.name-container');
  }
}

.image {
  aspect-ratio: 1.7;
}

.content {
  padding: 1.5rem 0.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include media('>=tablet-landscape') {
    padding: 1.5rem;
  }
}

.name-container[href] {
  @include reverse-underline;
}

.name {
  @include fonts__h4-all-caps;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.address {
  @include fonts__p;

  white-space: pre-wrap;
}

.phone,
.map {
  @include fonts__h6;
  @include underline;
}
