@mixin search-fade-in() {
  animation-name: search-fade-in;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes search-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
