@import "src/styles/sass-resources/sass-resources";
@import './../../../../styles/sass-resources/sass-resources';

.tab-nav {
  padding: 1rem 2rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    @include animated-gold-line;

    bottom: 0;
    top: 100%;
    height: 2px;
  }

  &:focus-visible {
    @include focus-inset;
  }

  &--active,
  &.content-tab:hover,
  &:focus-visible {
    &::after {
      @include gold-swipe-hover;
    }

    @include reverse-underline-active;
  }
}

.nav-text {
  @include fonts__h6;

  text-align: center;
}
