@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.hero-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  padding-top: 30px;

  @include media('>tablet-landscape') {
    padding-top: 50px;
    gap: 2rem;
  }
}

.headline {
  @include fonts__h1;
}

.body {
  @include fonts__h5;
}
