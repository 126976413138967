@import "src/styles/sass-resources/sass-resources";
@import './../../../../../../styles/sass-resources/sass-resources';

.main {
  display: flex;

  @include media('>=tablet-landscape') {
    display: none;
  }
}
