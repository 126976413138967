:root {
  --font-weight__book: #{$fonts__font-weight--book};
  --font-weight__regular: #{$fonts__font-weight--regular};
  --font-weight__medium: #{$fonts__font-weight--medium};
  --font-weight__heavy: #{$fonts__font-weight--heavy};
  --font-family__sans-serif: #{$fonts__font-family--sans-serif};
  --font-family__serif: #{$fonts__font-family--serif};
}

@include font-face(
  $fonts__font-family--burgess-pro,
  './../../assets/fonts/burgess-italic-pro.woff2',
  $fonts__font-weight--regular,
  italic
);
@include font-face(
  $fonts__font-family--burgess-pro,
  './../../assets/fonts/burgess-regular-pro.woff2',
  $fonts__font-weight--regular,
  normal
);
@include font-face(
  $fonts__font-family--avenir,
  './../../assets/fonts/065a6b14-b2cc-446e-9428-271c570df0d9.woff2',
  $fonts__font-weight--book,
  normal
);
@include font-face(
  $fonts__font-family--avenir,
  './../../assets/fonts/476612d9-282d-4f76-95cd-b4dd31e7ed21.woff2',
  $fonts__font-weight--book,
  italic
);
@include font-face(
  $fonts__font-family--avenir,
  './../../assets/fonts/17b90ef5-b63f-457b-a981-503bb7afe3c0.woff2',
  $fonts__font-weight--medium,
  normal
);
@include font-face(
  $fonts__font-family--avenir,
  './../../assets/fonts/deb5e718-7abb-4df3-9365-edfa95317090.woff2',
  $fonts__font-weight--medium,
  italic
);
@include font-face(
  $fonts__font-family--avenir,
  './../../assets/fonts/d513e15e-8f35-4129-ad05-481815e52625.woff2',
  $fonts__font-weight--heavy,
  normal
);
