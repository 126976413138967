@import "src/styles/sass-resources/sass-resources";
@import './../../../../../styles/sass-resources/sass-resources';

.wrapper {
  position: relative;
}

.main {
  display: none;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0%;
  transform: translateY(-100%);
  visibility: hidden;

  @include transition(transform, visibility);

  z-index: -1;

  &.active {
    transform: translateY(0%);
    visibility: visible;
  }

  @include media('>=tablet-landscape') {
    display: block;
  }
}
