@import "src/styles/sass-resources/sass-resources";
@import './../../../../../styles/sass-resources/sass-resources';

.main {
  z-index: 0;
  position: relative;

  .tabs {
    display: none;
  }

  @include media('>=tablet-landscape') {
    .tabs {
      display: block;
    }
  }
}

.tab-navs-container {
  background: var(--colors__white);
  position: relative;
  border-bottom: 1px solid var(--colors__gray-1);
  overflow: hidden;
  z-index: 1;
}

.tab-navs {
  display: flex;
  position: relative;
  overflow: hidden;
  justify-content: center;
  padding-bottom: 2px;

  & a,
  & button {
    flex-grow: 1;
  }
}

.nav-text {
  @include fonts__h6;

  text-align: center;
}

.tab-navs-outer,
.tab-navs-item {
  display: contents;
}

.tab-nav {
  padding: 1rem 2rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    @include animated-gold-line;

    bottom: 0;
    top: 100%;
    height: 2px;
  }

  &:focus-visible {
    @include focus-inset;
  }

  &--active,
  &:focus-visible {
    &::after {
      @include gold-swipe-hover;
    }

    @include reverse-underline-active;
  }
}

.underline {
  background: var(--colors__gold);
  height: 3px;
  position: absolute;
  bottom: -1px;
  pointer-events: none;

  @include transition(width, left, opacity, 0.1s linear);
}

.panels {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
}
