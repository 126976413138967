@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

$bp-two-col: '>=575px';
$bp-one-col: '<575px';

.main {
  margin: 0 auto;
  height: 100%;
  width: 100%;
  max-width: var(--cards_max-width, 336px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.has-link:focus-within,
.has-link:hover {
  @include ImageCtaHover;
  @include reverse-underline-active;
  @include gold-swipe-hover;
}

.row {
  align-items: center;

  @include container-query($bp-two-col) {
    max-width: none;
    height: auto;
    min-height: 400px;
    flex-direction: row;

    .image-link {
      --BaseLinkCard__image--padding-bottom: 0;

      display: flex;
      justify-content: center;
      width: 50%;
    }

    .image {
      max-height: 400px;
      max-width: var(--cards_max-width, 336px);
    }

    .content {
      display: flex;
      justify-content: center;
      width: 50%;

      > * {
        max-width: 480px;
      }

      @include container-query($bp-two-col) {
        padding: 32px 48px;
      }
    }
  }
}

.image-link {
  width: 100%;
  padding-bottom: var(--BaseLinkCard__image--padding-bottom, 32px);
}

.image {
  width: 100%;
  aspect-ratio: 0.8;
}

.content {
  display: flex;
  gap: var(--BaseLinkCard__content-gap, 20px);
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.title {
  @include fonts__h5-all-caps;
}

a .underline {
  @include reverse-underline;
}

.content-type {
  @include fonts__h6;
}

.description {
  @include fonts__p;
}

.date {
  @include fonts__h6;
}

.cta {
  margin-top: 30px;

  a.content:focus-visible & {
    @include swipe-underline-active;
  }
}
