@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

$bp-two-per-row: '>=575px';

.main {
  display: flex;
  flex-direction: column;
  gap: 32px;
  container-type: inline-size;
}

.flex-container {
  display: flex;
  justify-content: center;
  column-gap: 16px;
  row-gap: 32px;
  flex-direction: column;
}

.horizontal {
  @include container-query($bp-two-per-row) {
    flex-flow: row wrap;
    justify-content: center;
  }

  @include container-query('>=tablet-landscape') {
    flex-wrap: nowrap;
  }
}

.horizontal-list-item {
  width: 100%;
  max-width: var(--cards_max-width, 336px);
}

.header-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description {
  display: block;
  text-align: center;
  max-width: 657px;
}
