@import "src/styles/sass-resources/sass-resources";
@import './../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex-grow: 1;
}
