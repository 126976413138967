@import "src/styles/sass-resources/sass-resources";
@import './../../../../styles/sass-resources/sass-resources';

.main {
  padding: 2rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.text {
  @include fonts__p;

  margin-bottom: 1rem;
}

.link {
  display: block;
}
