@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.breadcrumb-container {
  @include search-fade-in;
}

.breadcrumb-list {
  transform: translateX(-8px);
}

.breadcrumb-container,
.breadcrumb-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

.breadcrumb-list-label {
  @include fonts__h6;

  padding: 8px 8px 8px 0;
}

.breadcrumb {
  display: flex;
  align-items: center;

  &:not(.query-breadcrumb) {
    text-transform: uppercase;
  }
}

.breadcrumb-label {
  @include fonts__h6;

  line-height: 1;
}

.breadcrumb-close-button {
  @include transition(background-color);

  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px;
  background-color: transparent;

  &:hover {
    background-color: var(--colors__gray-1);
  }
}

.clear-filters-container {
  padding-left: 8px;
  transform: translateY(1px);
}

.clear-filters {
  margin: 7px 0;
}
