@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include media('>=tablet-landscape') {
    flex-direction: row;
    border-bottom: 0;
  }

  &:hover {
    @include ImageCtaHover;
    @include reverse-underline-active('.name-container');
  }

  @include target-pdf {
    flex-direction: row;
    border-bottom: none;
  }
}

.role {
  @include fonts__h6;

  margin-bottom: 4px;

  @include target-pdf {
    @include fonts__pdf-h4;
  }
}

.name-container {
  &[href] {
    @include reverse-underline;

    @include target-pdf {
      text-decoration: none;
    }
  }
}

.name {
  @include fonts__h4-all-caps;

  margin-bottom: 1rem;
  display: block;

  @include target-pdf {
    @include fonts__pdf-p-all-caps;

    margin-bottom: 0;
  }
}

.email {
  @include target-pdf {
    margin-top: 4px;
  }
}

.email-span {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.location {
  margin-right: 1rem;
  display: inline-block;

  @include target-pdf {
    margin: 0;
  }
}

.email,
.location,
.phone {
  @include fonts__h6;

  &[href] {
    @include fonts__h6-underline;
    @include underline;
  }

  @include target-pdf {
    @include fonts__pdf-h4;

    padding: 0 2px;

    &:not(.email) {
      text-decoration: none;
    }
  }
}

.image {
  position: relative;
  display: none;
  flex: 1 0;
  align-self: flex-start;

  @include media('>=tablet-landscape') {
    display: block;
  }

  @include target-pdf {
    display: block;
  }
}

.image-container {
  aspect-ratio: 0.75;
  height: 240px;
  width: 192px;
  position: relative;
  display: flex;

  @include target-pdf {
    width: 61px;
    height: 76px;
    aspect-ratio: initial;
  }
}

.content {
  flex: 2;
  max-width: var(--cards_max-width, 336px);
  width: 100%;
  padding: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: var(--content-card-border-bottom);

  @include media('>=tablet-landscape') {
    max-width: none;
    border-bottom: none;
  }

  @include target-pdf {
    padding: 0;
    border-bottom: none;
  }
}

.office {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 4px;
}

.office-container {
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
  gap: 0.5rem;

  @include target-pdf {
    display: block;
    margin-bottom: 0;
  }
}
