@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.container {
  padding: 0 100px;
}

.main {
  display: block;
  background: var(--colors__gray-1b);

  &[href]:hover {
    @include ImageCtaHover;
    @include reverse-underline-active;
  }
}

.image {
  height: 495px;
}

.text-container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  text-align: center;
  padding: 48px 36px;
}

.headline {
  @include fonts__h3-all-caps;

  [href] & {
    @include reverse-underline;
  }
}

.body-text {
  @include fonts__h5;
}
