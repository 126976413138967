@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  position: relative;
  width: 100%;
}

.image {
  width: 100%;
  height: 100%;
  display: block;
}

.cta-container {
  @include animated-gold-swipe(var(--colors__gold-transparent));

  position: absolute;
  height: 90%;
  width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;

  @include target-pdf {
    display: none;
  }
}

.cta-button {
  @include transition(transform);

  --BoxCta__background-size: 100% 100%;
  --BoxCta__border-color: var(--colors__gold);

  transform: translateY(var(--ImageCtaHover__translateY, 16px));
  padding: 1rem;
  text-align: center;
}

.story {
  &:hover {
    @include ImageCtaHover;
  }
}
