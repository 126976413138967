@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.embed {
  iframe {
    display: block;
  }
}

.exp-editor {
  @include target-exp-editor {
    text-align: center;
    display: block;
    margin-top: 1rem;
  }
}
