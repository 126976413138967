@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.header {
  display: block;

  @include fonts__h4-all-caps;

  margin-bottom: 2rem;
}

.eyebrow {
  @include fonts__h6;

  margin-bottom: 1rem;
}

.title {
  @include fonts__h5-all-caps;

  display: block;
  margin-bottom: 1rem;
}

.highlight {
  padding: 6rem 2rem;
}
