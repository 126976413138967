@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include target-pdf {
    display: block;
  }
}

.image-container {
  position: relative;
  width: 100%;
  aspect-ratio: 1.5;
}

.media-grid-item {
  .image-container {
    aspect-ratio: var(--media-grid-aspect-ratio, 0.88);

    @include media('>=tablet-landscape') {
      max-height: 466px;
    }

    @include target-pdf {
      aspect-ratio: initial;
      max-height: none;
      padding-bottom: calc(var(--media-grid-aspect-ratio) * 100%);
    }
  }
}

.play-button-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(var(--colors__black-rgb, 0 0 0) / 70%);

  @include target-pdf {
    display: none;
  }
}

.headline {
  @include fonts__h4-all-caps;

  text-align: center;
  width: 100%;
  padding: 32px 0;
  background-color: var(--colors__gray-1b);
}

.cta {
  margin-top: 34px;
}
