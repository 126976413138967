@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.side-card {
  --BaseLinkCard__content-gap: 16px;

  @include media('>=tablet-portrait') {
    max-width: 379px;
  }

  &::before {
    @include animated-gold-line;
  }
}

.main-card {
  margin: 0 auto;
  padding: 0;
  width: 100%;

  @include media('>=tablet-portrait') {
    max-width: var(--cards_max-width, 336px);
  }
}

.with-image {
  justify-content: center;
}

.cta {
  margin-top: 30px;
}

.date {
  @include fonts__p;
}
