@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.play-button {
  height: 73px;
  width: 73px;

  svg {
    height: 100%;
    width: 100%;
    overflow: visible;
  }

  circle,
  path {
    @include transition(fill, stroke);
  }

  circle {
    fill: transparent;
  }

  &:hover,
  &:focus-visible {
    circle {
      fill: var(--colors__white);
    }

    path {
      fill: var(--colors__black);
    }
  }

  &.dark {
    circle {
      stroke: var(--colors__black);
    }

    path {
      fill: var(--colors__black);
    }

    &:hover,
    &:focus-visible {
      circle {
        stroke: var(--colors__white);
      }
    }
  }
}
