@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

$bp-two-per-row: '>=500px';

.main {
  container-type: inline-size;
}

.stats {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  gap: 1rem;

  @include container-query($bp-two-per-row) {
    flex-direction: row;
  }
}

.stat {
  flex-grow: 1;
  display: flex;

  @include container-query($bp-two-per-row) {
    width: calc(50% - 1rem);
  }

  @include container-query('>=tablet-landscape') {
    width: calc(33.33% - 1rem);
  }
}
