@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  container-type: inline-size;
}

.lists {
  display: flex;
  flex-direction: column;
  gap: 50px;

  @container (width > 500px) {
    flex-direction: row;

    > div {
      flex: 1;
    }
  }
}
