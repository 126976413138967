@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: column;
}

.insight-types-list {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.insight-types-item__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 27.89px;

  @include media('>=tablet-landscape') {
    min-height: 31px;
  }
}

.insight-types-item__heading {
  @include fonts__h5-all-caps;
}

.insight-types-item__cta {
  margin-left: auto;
}

.insight-result-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;

  @include media('>=tablet-landscape') {
    flex-direction: row;
  }
}

.insight-result-item {
  flex-basis: 50%;
  background-color: var(--colors__gray-1b);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.insight-result-item__link {
  padding: 20px 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
  height: 100%;
}

a.insight-result-item__link {
  cursor: pointer;

  &::after {
    @include animated-gold-line;
  }

  &:hover,
  &:focus-visible {
    @include gold-swipe-hover;
  }
}

.insight-result-item__content-type {
  @include fonts__h6;

  text-transform: uppercase;
}

.insight-result-item__heading {
  @include fonts__h5-all-caps;
}

.insight-result-item__date {
  @include fonts__p;
}
