@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  --OfficeLandingList__vertical-spacing: 3rem;

  display: flex;
  flex-direction: column;
  margin-top: var(--OfficeLandingList__vertical-spacing);

  @include media('>=tablet-portrait') {
    --OfficeLandingList__vertical-spacing: 4rem;
  }

  @include media('>=tablet-landscape') {
    --OfficeLandingList__vertical-spacing: 4.5rem;
  }

  @include media('>=desktop') {
    --OfficeLandingList__vertical-spacing: 5rem;
  }
}

.buttons {
  border-bottom: 1px solid var(--colors__gray-1);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include media('>=tablet-portrait') {
    font-size: 1rem;
    gap: 1rem;
  }

  @include media('>=tablet-portrait') {
    gap: 2rem;
  }
}

.button {
  @include fonts__h6;
  @include reverse-underline;

  padding: 1rem;

  @include media('>=tablet-portrait') {
    font-size: 1rem;
    padding: 1rem 2rem;
  }
}

.regions {
  display: flex;
  flex-direction: column;
}

.region-item {
  padding-top: var(--OfficeLandingList__vertical-spacing);
}

.region-name {
  margin-bottom: 1.5rem;

  @include media('>=tablet-portrait') {
    margin-bottom: var(--OfficeLandingList__vertical-spacing);
  }
}

.offices {
  display: grid;

  // grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-columns: 1fr;
  gap: 20px;

  @include media('>=phone') {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media('>=tablet-portrait') {
    gap: 2rem 20px;
  }

  @include media('>=tablet-landscape') {
    grid-template-columns: repeat(4, 1fr);
    gap: 2.5rem 20px;
  }

  @include media('>=desktop') {
    gap: 3rem 20px;
  }
}
