@import "src/styles/sass-resources/sass-resources";
@import './../../../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  column-gap: 4rem;
  row-gap: 1rem;
  padding: 0;

  @include media('>=tablet-landscape') {
    display: grid;
    row-gap: 0;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: min-content 1fr;
    padding: 2rem 1.5rem;
    max-height: calc(100vh - var(--NavigationHeader__height, 0));
    overflow: auto;
  }
}

.links {
  @include media('>=tablet-landscape') {
    grid-row-start: 1;
  }
}

.trending-topics-links {
  @include media('>=tablet-landscape') {
    grid-row-end: 2;
  }
}

.featured-links {
  @include media('>=tablet-landscape') {
    grid-row-end: 3;
  }
}

.view-all-links {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 0 96px;

  @include media('>=tablet-landscape') {
    padding: 0;
    grid-column-start: 1;
    grid-row-start: 2;
  }
}

.link {
  @include fonts__h5;
  @include reverse-underline;

  margin-bottom: 1rem;
  display: inline-block;

  @include media('>=tablet-landscape') {
    margin-bottom: 2rem;
  }
}

.label {
  padding: 1rem 0;
  border-bottom: 1px solid var(--colors__gray-1);
  margin-bottom: 1.5rem;

  @include fonts__h6;

  @include media('>=tablet-landscape') {
    padding: 1.5rem 0;
  }
}

.trending-topics {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 1rem;

  @include media('>=tablet-landscape') {
    flex-direction: row;
  }
}

.trending-topic {
  margin-bottom: 1rem;
  margin-right: 1rem;
}

.view-all-insights {
  @include media('>=tablet-landscape') {
    display: inline-block;
  }
}

.nested-text {
  pointer-events: none;
}
