@import "src/styles/sass-resources/sass-resources";
@import './../../../../styles/sass-resources/sass-resources';

.panel {
  background: var(--colors__white);
  animation-timing-function: ease-in-out;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  width: 100%;
  overflow: auto;
  position: absolute;
  visibility: hidden;

  @include transition(visibility);

  &:focus-visible {
    @include focus-inset;
  }

  &.active {
    visibility: visible;
  }

  &.content-tab {
    display: none;
    position: relative;

    &.active {
      display: block;
      animation-name: fade-in;
      animation-timing-function: ease-in-out;
      animation-duration: 0.4s;
      animation-fill-mode: forwards;
    }
  }

  &.navbar {
    top: 0;
    left: 0;
    transform: translate(0, -100%);

    @include transition(transform, visibility);

    &.active {
      transform: translate(0, 0%);
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
