@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  overflow: hidden;

  @include target-exp-editor {
    --FlexibleFeatureItem__translate: 0;
  }
}

.animate-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.date {
  @include fonts__h6;
}
