@mixin gold-swipe-hover {
  --GoldSwipeHover__bg-size: 100% 100%;
  --GoldSwipeHover__opacity: 1;
}

@mixin ImageCtaHover {
  @include target-devices-with-hover {
    @include gold-swipe-hover;

    --ImageCtaHover__translateY: 0px;
  }
}

@mixin animated-gold-swipe($color: var(--colors__gold)) {
  @include transition(background-size, opacity);

  opacity: var(--GoldSwipeHover__opacity, 0);
  background: linear-gradient($color, $color);
  background-size: var(--GoldSwipeHover__bg-size, 0% 100%);
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

@mixin animated-gold-line {
  @include animated-gold-swipe;

  content: '';
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 8px;
}
