@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.caption {
  @include fonts__caption;

  text-align: center;
  display: block;
  padding: 0 2rem;

  @include media('>=tablet-landscape') {
    padding: 0 8rem;
  }
}

.image {
  position: relative;
  margin-bottom: 1rem;
  aspect-ratio: 2;

  @include media('>=tablet-landscape') {
    min-height: 500px;
    max-width: 100%;
  }
}
