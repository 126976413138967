@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

$two-per-row: '>=500px';
$three-per-row: '>=tablet-portrait';
$four-per-row: '>=tablet-landscape';

.main {
  --IconCards__gap: 3rem;

  container-type: inline-size;
}

.description {
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--IconCards__gap);

  @include container-query($two-per-row) {
    --IconCards__gap: 2rem;

    &--2,
    &--3,
    &--4 {
      --IconCards__width: calc((100% - var(--IconCards__gap)) / 2);
    }
  }

  @include container-query($three-per-row) {
    &--3 {
      --IconCards__width: calc((100% - var(--IconCards__gap) * 2) / 3);
    }
  }

  @include container-query($four-per-row) {
    &--4 {
      --IconCards__width: calc((100% - var(--IconCards__gap) * 3) / 4);
    }
  }

  .header + &,
  .description + & {
    margin-top: 2rem;

    @include container-query($two-per-row) {
      margin-top: 56px;
    }
  }
}

.item {
  width: var(--IconCards__width, 100%);
}
