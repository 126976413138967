@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  background: var(--colors__black);
  padding: 1rem 2rem;
  color: var(--colors__white);
  display: flex;
  align-items: center;
  flex-direction: column;

  @include target-print {
    display: none;
  }
}

.social-container {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .social-label {
    @include fonts__h6;

    text-align: center;
    text-transform: uppercase;
    margin-bottom: 1rem;
    white-space: nowrap;
  }

  @include media('>=tablet-landscape') {
    flex-direction: row;
  }
}

.logo {
  width: 125px;
  fill: var(--colors__white);
}

.top {
  @include media('>=tablet-landscape') {
    margin-bottom: 3rem;
  }
}

.spacer {
  width: 100%;
  background: var(--colors__white);
  margin: 1rem 0;
  height: 1px;

  @include media('>=tablet-landscape') {
    display: none;
  }
}

.bottom {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;

  @include media('>=tablet-landscape') {
    justify-content: center;
    flex-direction: row;
    align-items: flex-start;
    gap: 48px;
  }
}

.footer-links {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 24px;
  text-align: center;

  @include media('>=tablet-landscape') {
    flex: 3;
    flex-direction: row;
    margin-bottom: 0;
  }
}

.footer-link {
  text-transform: uppercase;
  margin: 0;

  @include media('>=tablet-landscape') {
    width: auto;
  }
}

.copyright {
  text-align: center;
  flex-shrink: 1;

  @include media('>=tablet-landscape') {
    max-width: 25%;
  }
}

.disclaimer {
  text-align: center;
  margin-top: 1rem;
  font-size: 14px;
}
