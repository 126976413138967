@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;

  // moved from insightsagenda to filter if theres no data
  padding-bottom: 20px;
  border-bottom: 1px solid var(--colors__black);

  @include media('>=tablet-portrait') {
    align-items: flex-start;
    flex-direction: row;
    gap: 80px;
  }

  @include target-pdf {
    display: block;
    border-color: var(--colors__gray-1);
    padding-bottom: var(--pdf__text-spacing-y);
  }
}

.time-container {
  flex-shrink: 0;

  @include target-pdf {
    @include fonts__pdf-p;

    margin-bottom: 4px;
  }
}

.content-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include media('>=tablet-portrait') {
    flex: 0 1 712px;
  }

  @include target-pdf {
    display: block;
  }
}

.title {
  @include fonts__h5-all-caps;

  @include target-pdf {
    @include fonts__pdf-h2;
  }
}

.description {
  @include target-pdf {
    margin-top: var(--pdf__text-spacing-y);
  }
}

.link-container,
.speakers-container {
  padding-top: 20px;
  border-top: 1px solid var(--colors__black);

  @include target-pdf {
    padding-top: calc(var(--pdf__text-spacing-y) * 1.5);
    border: none;
  }
}

.link-title,
.speakers-title {
  @include fonts__h6;

  margin-bottom: 12px;

  @include target-pdf {
    @include fonts__pdf-h3;

    margin-bottom: var(--pdf__text-spacing-y);
  }
}

.link {
  @include underline;

  display: inline;

  @include target-pdf {
    text-decoration: none;
  }
}
