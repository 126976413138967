@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.result-stats {
  @include fonts__h6;

  color: var(--colors__gray-2);

  @include media('>=tablet-landscape') {
    min-width: max-content;
  }
}
