@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.title {
  @include fonts__h4-all-caps;
}

.subtitle {
  @include fonts__p;
}

.note {
  margin-top: 50px;
  padding: 20px;
  background-color: var(--colors__white);
  border: 2px solid var(--colors__gold);
}

.chart-module {
  background: var(--colors__gray-1b);
  padding: 32px;

  @include media('>=tablet-landscape') {
    padding: 40px 50px;
  }
}

.barchart {
  margin-left: -30px;
  margin-right: -30px;
}

.supplementalText {
  :global {
    .module-header {
      @include fonts__h5-all-caps;

      border-bottom: 1px solid var(--colors__gray-1);
      padding: 10px 0;
    }
  }
}

.main-flex {
  @include media('>=tablet-landscape') {
    $chart-width: 55%;
    $rte-column-gap: 3%;

    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    &--reverse {
      flex-direction: row-reverse;
    }

    .column {
      width: 100% - ($chart-width + $rte-column-gap);
    }

    .chart-module {
      width: $chart-width;
    }
  }
}
