@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  container-type: inline-size;
}

.container {
  position: relative;
  display: flex;
  padding: 18px;
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 11px;
  position: relative;
  min-height: 405px;
  width: 100%;
  padding: 84px 13px 47px;
  cursor: pointer;
  background-color: rgb(var(--colors__white-rgb) / 90%);

  @include container-query('>=696px') {
    width: 50%;
  }
}

.headline {
  @include fonts__h2;

  text-align: center;
}

.cta-button {
  margin-top: 16px;
}
