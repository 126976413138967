@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;

  @include container-query('>=tablet-landscape') {
    padding: 24px 64px 0;
  }

  @include container-query('>=tablet-portrait') {
    padding: 24px 32px 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 21px;
  height: 100%;
  padding: 24px;
  border-bottom: var(--Content-Border-Bottom, 1px solid var(--colors__gray-1));

  @include container-query('>=tablet-portrait') {
    max-width: 454px;
  }
}

.title {
  @include fonts__h4-all-caps;
}

.body {
  @include fonts__p;
}
