@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.banner {
  padding: 56px 32px;
  display: flex;
  justify-content: center;
  position: relative;
}

.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 33px;
  text-align: center;
  position: relative;
}

.headline {
  @include fonts__h3-all-caps;

  max-width: 600px;

  @include media('>=tablet-landscape') {
    max-width: 800px;
  }
}

.description {
  @include fonts__p;

  max-width: 395px;
}
