@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.courts {
  margin-top: 1.5rem;

  @include target-pdf {
    margin-top: var(--pdf__section-spacing);
  }
}

.subhead.subhead {
  @include fonts__h5-all-caps;
}
