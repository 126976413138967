@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  $short-duration: 0.0001s;

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(0 0 0 / 50%);
  z-index: var(--overlay-z-index);
  opacity: 0;
  visibility: hidden;

  @include transition(opacity, visibility var(--transition-duration) $short-duration);

  &--visible {
    visibility: visible;
    opacity: 1;
  }
}
