@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.no-bullets {
  --RichText__list-style-type: none;
  --RichText__list-margin-left: 0;
}

.columns {
  --RichText__spacing-y: 24px;

  @include media('>=tablet-portrait') {
    columns: 2;
  }
}

.link {
  @include fonts__h6-underline;
}
