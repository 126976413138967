@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.group {
  &:not(:first-child) {
    margin-top: var(--pdf__section-spacing);
  }
}

.article-title,
.article-source {
  @include target-pdf {
    text-decoration: none;
    margin-right: 2px;
  }
}

.article-source {
  font-style: italic;
}
