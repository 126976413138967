@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  text-align: center;
}

.job-title {
  @include fonts__h1;

  margin-bottom: 1rem;
}

.location {
  @include fonts__h4-all-caps;
}
