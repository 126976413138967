@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  text-align: center;
  container-type: inline-size;
}

.module-wrapper {
  display: contents;
}

.grid {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;

  @include container-query('>=tablet-portrait') {
    flex-direction: row;
  }
}

.item {
  position: relative;

  --FlexibleFeatureItem__spacing: 4rem;

  padding: var(--FlexibleFeatureItem__spacing) 0
    var(--FlexibleFeatureItem__margin-bottom, var(--FlexibleFeatureItem__spacing)) 0;

  &:last-child {
    --FlexibleFeatureItem__border-bottom: 0;
  }

  border-bottom: var(--FlexibleFeatureItem__border-bottom, 1px solid var(--colors__gray-1));

  @include container-query('>=tablet-portrait') {
    width: 50%;

    &:nth-child(odd) {
      &::after {
        content: '';
        position: absolute;
        top: var(--BasicContentFlexibleFeatures__top, 50%);
        left: 100%;
        width: 1px;
        height: var(
          --BasicContentFlexibleFeatures__height,
          calc(100% - var(--FlexibleFeatureItem__spacing) * 2)
        );
        transform: var(--BasicContentFlexibleFeatures__transform, translateY(-50%));
        background-color: var(--colors__gray-1);
      }
    }

    &:last-child::after {
      display: none;
    }

    &:last-child,
    &:nth-child(odd):nth-last-child(2) {
      --FlexibleFeatureItem__border-bottom: 0;
      --FlexibleFeatureItem__margin-bottom: 0;

      &::after {
        --BasicContentFlexibleFeatures__top: var(--FlexibleFeatureItem__spacing);
        --BasicContentFlexibleFeatures__height: calc(100% - var(--FlexibleFeatureItem__spacing));
        --BasicContentFlexibleFeatures__transform: none;
      }
    }
  }
}
