@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.container {
  display: grid;
  overflow: hidden;
  width: 100%;

  @include transition(grid-template-rows);

  &[aria-hidden='true'] {
    --BasicContentRichText__visibility: hidden;
    --BasicContentRichText__transition-delay: var(--transition-duration);

    grid-template-rows: 0fr;
  }

  &[aria-hidden='false'] {
    --BasicContentRichText__visibility: visible;

    grid-template-rows: 1fr;
  }

  @include target-pdf {
    --BasicContentRichText__visibility: visible;

    overflow: visible;
    display: block;
  }

  @include target-print {
    --BasicContentRichText__visibility: visible;

    overflow: visible;
    display: block;
  }
}

.read-more-text {
  overflow: hidden;
  padding-top: var(--RichText__spacing-y);
  visibility: var(--BasicContentRichText__visibility);
  transition-delay: var(--BasicContentRichText__transition-delay);
}

.cta {
  @include target-pdf {
    display: none;
  }
}
