@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

$aspect-ratio: 2;

.main {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include target-pdf {
    display: block;
  }
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: $aspect-ratio;

  @include target-pdf {
    height: auto;
    aspect-ratio: initial;
    padding-bottom: 1 / $aspect-ratio * 100%;
  }
}

.caption {
  text-align: center;
  padding: 0 24px;

  @include target-pdf {
    margin-top: var(--pdf__text-spacing-y);
    text-align: center;
    display: block;

    @include fonts__pdf-p;
  }
}

.media-grid-item {
  display: block;

  .image-container {
    aspect-ratio: var(--media-grid-aspect-ratio, 0.88);

    @include media('>=tablet-landscape') {
      max-height: 466px;
    }

    @include target-pdf {
      aspect-ratio: initial;
      padding-bottom: calc(var(--media-grid-aspect-ratio) * 100%);
    }
  }
}
