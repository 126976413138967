@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.contact-cards {
  display: grid;

  @include media('>=tablet-landscape') {
    flex-direction: row;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
  }

  @include target-pdf {
    display: block;
  }
}

.contact-cards.hide-images {
  @include media('>=tablet-landscape') {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;

    .contact-card {
      max-width: 375px;
    }
  }
}

.contact-card {
  width: 100%;

  @include media('>=tablet-landscape') {
    margin-bottom: 2rem;
  }

  &:not(:last-of-type) {
    --content-card-border-bottom: 1px solid var(--colors__gray-1);
  }

  @include target-pdf {
    $spacing: 1rem;

    display: inline-block;
    vertical-align: top;
    margin-bottom: 0;
    width: calc(50% - #{$spacing / 2});

    &:nth-child(even) {
      margin-left: $spacing;
    }

    &:nth-child(n + 3) {
      padding-top: $spacing;
    }
  }
}

.hide-images .contact-card {
  @include media('>=tablet-landscape') {
    width: 100%;
  }
}

.category {
  margin-bottom: 1rem;

  @include media('>=tablet-landscape') {
    margin-bottom: 2rem;
  }
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  @include target-pdf {
    display: none;
  }
}
