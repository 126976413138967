@import "src/styles/sass-resources/sass-resources";
@import './../../../../../styles/sass-resources/sass-resources';

.main {
  --NavPanel__column-gap: 2rem;

  background: var(--colors__white);
  width: 100%;
  padding: 4rem;

  @include transition(top 0.5s ease-in-out);

  z-index: -1;
  max-height: calc(100vh - var(--NavigationHeader__height, 0));
  overflow: auto;
}

.nav-panel-links {
  display: flex;
  justify-content: center;
  gap: 1rem var(--NavPanel__column-gap);
  flex-wrap: wrap;
}

.nav-panel-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @include media('>=tablet-landscape') {
    width: calc(33.33% - var(--NavPanel__column-gap) * 2 / 3);
  }

  @include media('>=desktop') {
    width: calc(25% - var(--NavPanel__column-gap) * 3 / 4);
  }
}
