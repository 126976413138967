:root {
  --colors__white: #fff;
  --colors__white-transparent: rgb(255 255 255 / 90%);
  --colors__white-rgb: 255 255 255;
  --colors__black: #1d1d1b;
  --colors__black-transparent: rgb(0 0 0 / 60%);
  --colors__black-rgb: 0 0 0;
  --colors__gold: #b2985f;
  --colors__gold-transparent: rgb(178 152 95 / 80%);
  --colors__gray-1: #d8d8d8;
  --colors__gray-1b: #f5f5f5;
  --colors__gray-1c: #e7e7e5;
  --colors__gray-2: #75756c;
  --colors__gray-3: #444;
  --colors__gray-opaque: rgb(255 255 255 / 80%);
}

/* if you add a color here make sure to add it to STYLEGUIDE.mockdata.tsx */
