@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main {
  opacity: 0;
  transform: translateY(var(--FadeInContainer__initial-y, 24px));

  @include target-pdf {
    opacity: 1 !important;
    transform: none !important;
  }

  @include target-print {
    opacity: 1;
    transform: none;
    animation: none;
  }
}

.in-view {
  animation-name: fade-in;
  animation-duration: var(--FadeInContainer__animation-duration, 0.7s);
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

.slide-in-from-right {
  animation-name: slide-in-from-right;
  animation-duration: var(--FadeInContainer__animation-duration, 0.7s);
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

.slide-in-from-left {
  animation-name: slide-in-from-left;
  animation-duration: var(--FadeInContainer__animation-duration, 0.7s);
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(var(--FadeInContainer__initial-y, 24px));
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-in-from-right {
  0% {
    opacity: 0;
    transform: translateX(var(--AnimateInContainer__initial-x-right, 100%));
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes slide-in-from-left {
  0% {
    opacity: 0;
    transform: translateX(calc(-1 * var(--AnimateInContainer__initial-x-left, 100%)));
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
