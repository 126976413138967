@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

@mixin vertical-divider {
  content: '';
  width: 1px;
  background: var(--colors__gray-1);
  height: var(---divider-height);
  position: absolute;
  top: calc(-1 * var(---divider-height));
  left: 50%;
  transform: translateX(-50%);

  @include target-pdf {
    content: none;
  }
}

.quotation-mark {
  position: relative;

  @include target-pdf {
    display: none;
  }
}

.quote-text {
  @include fonts__quote;

  margin-top: var(---divider-height);
  text-align: center;
  position: relative;

  &::after {
    @include vertical-divider;
  }

  @include target-pdf {
    @include fonts__pdf-quote;

    margin-top: 0;
  }
}

.quote-attribution {
  ---divider-height: 3rem;

  padding-top: 0.5rem;
  position: relative;
  display: inline-block;
  margin-top: var(---divider-height);
  text-align: center;

  @include fonts__h6;

  &::before {
    @include vertical-divider;
  }

  @include target-pdf {
    @include fonts__pdf-h3;

    margin-top: var(--pdf__text-spacing-y);
  }
}

.main {
  ---divider-height: 5rem;

  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 3rem;
  padding-bottom: 3rem;

  &--small {
    padding: 2rem;

    .quote-text,
    .quote-attribution {
      margin-top: 1rem;

      ---divider-height: 0rem;
    }
  }

  @include media('>tablet-landscape') {
    padding: 4rem;

    &--small {
      padding: 2rem;
    }
  }

  @include target-pdf {
    ---divider-height: 0;

    padding-top: var(--pdf__section-spacing);
    padding-bottom: calc(var(--pdf__section-spacing) / 2);
  }
}

.White {
  background-color: var(--colors__white);
}

.Gray {
  background-color: var(--colors__gray-1b);

  @include target-pdf {
    background-color: var(--colors__white);
  }
}

.cta {
  text-align: center;

  &[href] {
    @include target-pdf {
      text-decoration: underline;
    }
  }
}
