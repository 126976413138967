@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.date {
  @include transition(opacity);

  @include target-pdf {
    opacity: 1 !important;
  }

  @include target-print {
    opacity: 1 !important;
  }
}
