@import "src/styles/sass-resources/sass-resources";
@import './../../../../../styles/sass-resources/sass-resources';

.wrapper {
  position: relative;
  z-index: 1;
}

.main {
  display: none;
  position: relative;
  width: 100%;
  background: var(--colors__white);
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 2;

  @include transition(transform, visibility);

  border-top: 1px solid var(--colors__gray-1);
  flex-direction: column;

  @include media('>=tablet-landscape') {
    display: flex;
    padding: 2rem;
    position: absolute;
    transform: translate(-50%, -100%);
    visibility: hidden;

    &--open {
      transform: translate(-50%, 0%);
      visibility: visible;
    }
  }
}

.locale-list {
  display: flex;
  justify-content: center;
  row-gap: 1rem;
  width: 100%;
  flex-wrap: wrap;
  position: absolute;
  left: 0;
  background: var(--colors__white);
  z-index: 0;
  top: 0%;

  @include transition(top 0.3s ease-in-out);

  @include media('>=tablet-landscape') {
    position: static;
  }

  &--open {
    top: 100%;
  }
}

.locale {
  padding: 0 0.5rem;
  position: relative;

  @include fonts__h6;

  a {
    @include underline;
  }

  &:not(:last-child) {
    border-right: 1px solid var(--colors__black);
  }

  @include media('>=tablet-landscape') {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 175px;
    padding: 0 2rem;
  }
}

// .locale-toggle {
//   display: none;
//   justify-content: center;
//   background: var(--colors__white);
//   z-index: 1;
//   padding: 1rem 2rem;

//   @include media('>=tablet-landscape') {
//     display: flex;
//   }
// }

.toggle-locales {
  display: none;
  align-items: center;

  @include media('>=tablet-landscape') {
    display: flex;
  }
}

.locale-text {
  margin-right: 0.5rem;

  @include fonts__h6;

  color: var(--colors__gray-2);

  @include media('>=tablet-landscape') {
    color: var(--colors__black);
  }
}

.arrow {
  transform-origin: center;
  transform: rotate(90deg);
  width: 10px;
  height: 16px;

  @include transition(transform 0.3s ease-in-out);

  &--open {
    transform: rotate(90deg) rotateY(180deg);
  }
}
