@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.results-list {
  display: flex;
  flex-direction: column;
}

.result-link {
  display: flex;
  padding: 16px 0;
  border-top: 1px solid var(--colors__gray-1);

  @include media('>=tablet-portrait') {
    padding: 24px 0;

    &:hover,
    &:focus {
      --underline__color: var(--colors__gold);
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &:only-child {
    padding-right: 48px;
  }
}

.content-type-and-date {
  @include fonts__h6;

  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.content-type {
  display: contents;

  &:not(:last-child) {
    &::after {
      content: '|';
      position: relative;
      top: -1px;
    }
  }
}

.title {
  @include fonts__h5-all-caps;

  a & {
    @include reverse-underline;
  }
}
