.sc-jss-empty-placeholder {
  width: 100%;
}

.scEmptyPlaceholder {
  position: relative;
  outline: 1px solid var(--exp-editor__placeholder-outline-color, currentColor);

  &::after {
    content: 'Click here to add components';
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 100%;
    padding: 1rem;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 1.25rem;
  }
}

.exp-editor-img-container {
  @include target-exp-editor {
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
