@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.search-box-container {
  width: 100%;
  position: relative;
}

.search-box {
  border: 1px solid var(--colors__gray-1);
  height: 62px;
  width: 100%;
  padding: 19px 16px;

  &::placeholder {
    @include fonts__h6;

    color: var(--colors__black);
  }

  &:focus-visible {
    @include focus;

    outline-offset: -0.5rem;
  }
}

.magnifying-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  display: flex;
  align-items: center;
}

.close-button {
  position: absolute;
  top: 50%;
  transform: rotate(45deg) translateY(-70%);
  right: 56px;
  display: flex;
  align-items: center;
}

.suggestions {
  @include dropdown-box-shadow;
  @include transition(opacity);

  position: absolute;
  z-index: 2;
  background-color: var(--colors__white);
  top: 100%;
  width: 100%;
  padding: 16px;
  text-align: center;
  pointer-events: none;

  &.expanded-list {
    opacity: 1;
    pointer-events: all;
  }

  &.collapsed-list {
    opacity: 0;
    pointer-events: none;
  }
}

.suggestions-heading {
  @include fonts__h6;

  color: var(--colors__gray-2);
  border-bottom: 1px solid var(--colors__gray-1c);
  width: fit-content;
  margin: 0 auto 16px;
  padding: 5px;
}

.suggestions-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.closed-list {
  opacity: 0;
  height: 0;
}

.suggestions-list-button {
  @include reverse-underline;
  @include fonts__h6;

  cursor: pointer;
}

.suggestions-list-item {
  &.selected-item {
    @include focus;
  }
}

.closed-heading {
  opacity: 0;
}

.trending-topics-heading {
  color: var(--colors__black);
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  text-align: left;
}

.trending-topics-list {
  flex-flow: row wrap;
  justify-content: flex-start;
}

.trending-topics-button {
  &:hover {
    cursor: pointer;
  }
}

.header {
  input::placeholder {
    text-align: center;
  }

  @include media('>=tablet-landscape') {
    input {
      text-align: center;
    }

    .magnifying-button {
      left: 16px;
      right: auto;
    }

    .close-button {
      right: 32px;
    }
  }
}
