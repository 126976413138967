@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.outer-wrapper {
  display: flex;
  justify-content: center;
  margin-top: var(--UnderlineCta__margin-top, 1.5rem);

  @include media('>=tablet-landscape') {
    margin-top: var(--UnderlineCta__margin-top, 3rem);
  }
}

.main {
  @include fonts__h6;
}

.underline-on-hover {
  @include swipe-underline-on-hover;
}

.underlined-by-default {
  @include swipe-by-default;
}
