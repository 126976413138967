@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

$animation-config: 0.5s ease forwards;
$mainPadding: 24px;
$contentGap: 21px;
$closeButtonDimension: 32px;
$iframeMaxHeight: calc(100vh - $contentGap - $closeButtonDimension - ($mainPadding * 2));

.main {
  @include overlay-background;

  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: $mainPadding;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fade-in $animation-config;
  z-index: var(--video-modal-z-index);

  &.fade-out {
    animation: fade-out $animation-config;
  }
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $contentGap;
  width: 100%;
  max-height: 100%;
  height: 100%;
}

.hidden-button {
  color: var(--colors__white);
  opacity: 0;

  @include transition(opacity);

  &:focus-visible {
    opacity: 1;
  }
}

.close-button {
  color: var(--colors__white);
  align-self: flex-end;
  height: $closeButtonDimension;
  width: $closeButtonDimension;

  svg {
    height: 100%;
    width: 100%;
  }
}

.iframe-container {
  max-width: 100%;
  height: 100%;
  aspect-ratio: 1.777; // default for if IframeAspectRatio doesn't get triggered
  object-fit: contain;

  iframe {
    max-height: $iframeMaxHeight;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
