@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

.main-dark {
  fill: var(--colors__white);
  width: 16px;
}

.main-light {
  fill: var(--colors__black);
  width: 1.4669rem;

  &:hover {
    @include transition(fill);

    fill: var(--colors__gold);
  }
}
