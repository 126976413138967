@import "src/styles/sass-resources/sass-resources";
@import './../../../styles/sass-resources/sass-resources';

$facet-label-gap: 16px;
$facet-checkbox-dimensions: 16px;

.facet-button {
  @include fonts__h6;
  @include transition(border-top);

  text-transform: uppercase;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  text-overflow: ellipsis;
  border-top: 1px solid var(--colors__gray-1);

  &:focus-visible {
    @include focus;
  }

  &:hover,
  &.active-button {
    border-top: 1px solid var(--colors__gold);
    cursor: pointer;
  }

  &[disabled] {
    border-top: 1px solid var(--colors__gray-1);
    color: var(--colors__gray-2);
    cursor: default;
  }
}

.facet-button-label {
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.facet-icon {
  @include transition(transform);

  &.expanded {
    transform: rotateX(-180deg);
  }
}

.facet-dropdown {
  @include transition(grid-template-rows);

  display: grid;
  grid-template-rows: 0fr;
  padding: 16px 0;
  min-height: 32px;
  width: 100%;
  pointer-events: none;

  &.expanded-dropdown {
    grid-template-rows: 1fr;
    pointer-events: all;
  }
}

.facet-list-item {
  @include transition(all);
}

.facet-option-list {
  @include transition(max-height);

  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.facet-option-item {
  @include reverse-underline;

  display: flex;
  align-items: center;
  gap: 16px;
  padding: 10px 0;

  &:hover {
    cursor: pointer;
  }

  &.disabled-option:hover {
    cursor: default;
  }

  &.focused-option {
    --underline__color: var(--colors__gold);
  }
}

.facet-option-label {
  max-width: calc(100% - $facet-label-gap - $facet-checkbox-dimensions);
  overflow-wrap: break-word;
}

.facet-option-checkbox {
  position: relative;
  height: $facet-checkbox-dimensions;
  width: $facet-checkbox-dimensions;
  border: 1px solid var(--colors__gray-1);
  flex-shrink: 0;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 40%;
    width: 40%;
    background-color: transparent;
  }

  .active-option & {
    &::after {
      background-color: var(--colors__black);
    }
  }
}

.facet-label {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.topic-label {
  text-transform: capitalize;
}

.show-more-item {
  margin: 8px 0 8px 4px;
}

.focused-show-more {
  @include focus;
}

.show-more-button {
  &:hover {
    cursor: pointer;
  }
}

// CONTENT TYPE FACET VARIATION STYLES

.section-dropdown-wrapper {
  @include media('<tablet-landscape') {
    background-color: var(--colors__gray-1b);
    padding: 30px 30px 0;
  }
}

.section-dropdown {
  @include media('>=tablet-landscape') {
    display: block;
    pointer-events: all;

    .section-button:focus-visible ~ & {
      @include focus;
    }
  }
}

.section-list {
  @include media('>=tablet-landscape') {
    @include fonts__h6;

    overflow: visible;
    background-color: transparent;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 4px;
    width: 100%;
  }
}

.section-item {
  @include media('>=tablet-landscape') {
    @include animated-gold-swipe;

    text-decoration: none;
    opacity: 1;
    flex: 0 1;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 10px;
    padding: 16px 24px;
    border: 1px solid var(--colors__black);

    &:not(.disabled-option):hover,
    &:not(.disabled-option).active-option {
      @include gold-swipe-hover;

      cursor: pointer;
    }

    &.focused-option {
      @include focus;
    }
  }
}

.section-button {
  @include media('>=tablet-landscape') {
    @include visually-hidden;
  }
}

.radio-input {
  @include media('>=tablet-landscape') {
    height: 16px;
    width: 16px;
    border: 1px solid black;
    border-radius: 50%;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      height: 8px;
      width: 8px;
      background-color: transparent;
    }

    .active-option & {
      &::after {
        background-color: var(--colors__black);
      }
    }
  }
}

.section-option-label {
  @include media('>=tablet-landscape') {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: max-content;

    .disabled-option > & {
      color: var(--colors__gray-2);
    }

    :not(.disabled-option) > &:hover {
      cursor: pointer;
    }
  }
}
