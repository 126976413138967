@import "src/styles/sass-resources/sass-resources";
@import './../../../../../../styles/sass-resources/sass-resources';

.main {
  position: relative;
}

.magnifying {
  display: none;

  @include media('>=tablet-landscape') {
    display: block;
  }
}

.arrow {
  @include media('>=tablet-landscape') {
    opacity: 0;
  }
}

.input {
  @include fonts__h6;

  width: 100%;
  color: var(--colors__gray-2);
  text-align: center;
}

.input-wrapper {
  border-top: 1px solid var(--colors__gray-1);
  border-bottom: 1px solid var(--colors__gray-1);
}

.input-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.results {
  padding: 1rem;
  text-align: center;
}

.result {
  @include fonts__h6;
}
